import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { showModal, hideModalsAndShow } from '../../redux/ui/actions'
import CheckoutButton from './CheckoutButton'
import Modal from '../display/Modal'
import Cart from './QuickList'
import Countdown from './Countdown'
import { getPaymentEntity, getTenantCart, getTenantEntity } from '../../redux/state'
import { TicketSupportLink } from '../entity/TicketSupportLink'

export const CartButton = props => {
  const { theme = 'light', className = '' } = props

  const dispatch = useDispatch()
  const history = useHistory()

  const cart = useSelector(state => getTenantCart(state))
  const tenant = useSelector(state => getTenantEntity(state))
  const entity = useSelector(state => (tenant ? getPaymentEntity(state, tenant.id) : null))

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('cart', 'quicklist', false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  const showCart = () => {
    dispatch(hideModalsAndShow('cart', 'quicklist', true))
  }

  if (!cart || !entity) {
    return null
  }

  return (
    <>
      <CheckoutButton
        mode="icon"
        className={className}
        handleClick={showCart}
      />
      <Modal
        resource="cart"
        id="quicklist"
        title="Your cart"
        theme={theme}>
        <Cart />
        <div className="text-end">
          <Countdown
            id={entity.id}
            className="mt-1 me-2 text-secondary"
          />
          <CheckoutButton
            className="btn-block btn-md-inline"
            handleClick={checkout}
          />
        </div>
        <TicketSupportLink
          id={entity.id}
          className={`mt-3 mb-0 small`}
          colour={'secondary'}
        />
      </Modal>
    </>
  )
}

export default CartButton
