import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import TicketList from './TicketList'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import { getDataObject } from '../../redux/state'
import { modalIsVisible } from '../../redux/ui/state'

export const TicketModal = props => {
  const { resource, id, includeParent = true, theme = 'light', category = null } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (window.location.hash.substring(1) === 'tickets') {
      dispatch(showModal(`tickets-${resource}`, id, true))
    }
  }, [])

  const data = useSelector(state => getDataObject(state, resource, id))

  const show = useSelector(state => modalIsVisible(state, `tickets-${resource}`, id))

  if (!data) return null

  const handleClose = () => {
    dispatch(showModal(`tickets-${resource}`, id, false))
  }

  const textColour = theme === 'dark' ? 'text-white' : ''

  return (
    <Modal
      show={show}
      aria-labelledby="login-modal"
      centered
      onHide={handleClose}
      size="lg"
      backdrop="static"
      data-bs-theme={theme}>
      <Modal.Header closeButton>
        <Modal.Title
          id="login-modal"
          className={`${textColour} fw-normal`}>
          Tickets to {data.name || data.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${textColour}`}>
        <TicketList
          resource={resource}
          id={id}
          includeParent={includeParent}
          category={category}
        />
      </Modal.Body>
    </Modal>
  )
}

export default TicketModal
