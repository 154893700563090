import Badge from 'react-bootstrap/Badge'
import TicketList from '../TicketList'
import Venue from '../Venue'
import Accordion from 'react-bootstrap/Accordion'

export default function BoxOfficeEvent(props) {
  const { event, index } = props

  const stock = event.offerings.reduce((agg, offering) => {
    if (agg === null) return null
    if (offering.stock !== null && offering.stock !== undefined) {
      agg += offering.stock
    } else {
      agg = null
    }
    return agg
  }, 0)

  let bg = 'danger'
  switch (true) {
    case stock >= 10:
      bg = 'success'
      break
    case stock >= 5:
      bg = 'warning'
      break
    case stock !== null:
      bg = 'danger'
  }

  return (
    <Accordion.Item eventKey={`${index}`}>
      <Accordion.Header>
        <span>
          {event.datetime ? (
            <>
              <span className="font-bold text-secondary me-2">
                {new Date(event.datetime).toLocaleDateString(undefined, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </span>
              <span className="text-primary me-2">
                {new Date(event.datetime).toLocaleTimeString(undefined, {
                  hour: 'numeric',
                  minute: 'numeric'
                })}
              </span>
            </>
          ) : null}
          {event.name}
        </span>
        <Venue
          className="text-small badge bg-secondary rounded-pill ms-2 mb-0"
          id={event.id}
        />
        {stock === null ? null : (
          <Badge
            bg={bg}
            pill
            className="ms-2">
            {stock}
          </Badge>
        )}
      </Accordion.Header>
      <Accordion.Body>
        <TicketList
          id={event.id}
          includeParent={false}
        />
      </Accordion.Body>
    </Accordion.Item>
  )
}
