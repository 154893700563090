import { useDispatch, useSelector } from 'react-redux'
import { getFeed } from '../../redux/feed/state'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faList } from '@fortawesome/free-solid-svg-icons'
import { sortFeed } from '../../redux/feed/actions'
import { useHistory, useLocation } from 'react-router'

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { name, className = '' } = props

  const feed = useSelector(state => getFeed(state, name))

  if (!feed) {
    return null
  }

  const selected = Array.isArray(feed.sort) && feed.sort.length ? feed.sort : ['-rating']

  const handleClick = sort => {
    dispatch(sortFeed(name, sort))
    const search = new URLSearchParams(location.search)
    search.set('sort', sort)
    history.push(`${location.pathname}?${search.toString()}`)
  }

  return (
    <ButtonGroup
      aria-label="Feed order"
      className={className}>
      <Button
        title="View programme"
        variant={(selected || []).includes('-rating') ? 'dark' : 'light'}
        onClick={() => handleClick('-rating')}>
        <FontAwesomeIcon icon={faList} />
        <span className="d-none d-md-inline ms-2">Programme</span>
      </Button>
      <Button
        title="View schedule"
        variant={(selected || []).includes('datetime') ? 'dark' : 'light'}
        onClick={() => handleClick('datetime')}>
        <FontAwesomeIcon icon={faClock} />
        <span className="d-none d-md-inline ms-2">Schedule</span>
      </Button>
    </ButtonGroup>
  )
}
