import {
  HIDE_ALL_MODALS,
  SHOW_MODAL,
  SET_LOGIN_MODAL_POSITION,
  PLAYER_FULLSCREEN,
  SHOW_COMMENTS,
  SHOW_COMMENT_DELETE_MODAL,
  SET_CURRENT_TENANT,
  SET_FEED_DISPLAY,
  OPEN_ACCORDION,
  TOGGLE_UI_ELEMENT,
  SET_LOADING_STATE
} from './actions'

import { SET_POST_AUTH_DESTINATION } from '../auth/actions'

import { RECEIVE_AUTHENTICATION } from '../auth/actions'

const defaultState = {
  login: {
    visible: false
  },
  streamevents: {
    new: {
      visible: false
    }
  },
  entities: {
    new: {
      visible: false
    }
  },
  credits: {
    visible: false
  },
  comments: {
    deletion: {
      visible: false,
      id: ''
    }
  },
  access: {}
}

const hideAllModals = state => {
  for (var i in state) {
    if (i === 'visible') {
      state[i] = false
    } else if (state[i] !== null && typeof state[i] === 'object') {
      hideAllModals(state[i])
    }
  }
  return state
}

export const modals = (state = defaultState, action) => {
  switch (action.type) {
    case HIDE_ALL_MODALS:
      return hideAllModals(state)
    case SHOW_MODAL:
      const newState = action.id ? { [action.id]: { visible: action.visible } } : { visible: action.visible }
      return { ...state, ...{ [action.modal]: newState } }
    case RECEIVE_AUTHENTICATION:
      return { ...state, ...{ login: { visible: false } } }
    case SHOW_COMMENT_DELETE_MODAL:
      return {
        ...state,
        ...{ comments: { ...state.comments, ...{ deletion: { visible: action.visible, id: action.id } } } }
      }
    case SET_LOGIN_MODAL_POSITION:
      return { ...state, ...{ login: { ...state.login, ...{ position: action.index } } } }
    default:
      return state
  }
}

const comments = (state = {}, action) => {
  return {
    ...state,
    ...{
      [action.resource]: {
        ...(state[action.resource] || { [action.id]: {} }),
        ...{
          [action.id]: {
            ...(state[action.resource] ? state[action.resource][action.id] : {}),
            ...{ visible: action.visible }
          }
        }
      }
    }
  }
}

export const ui = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_FULLSCREEN:
      return { ...state, ...{ player: { ...state.player, ...{ fullscreen: action.fullscreen } } } }
    case SET_CURRENT_TENANT:
      return { ...state, ...{ tenant: { type: action.resource, id: action.id } } }
    case SHOW_COMMENTS:
      return { ...state, ...{ comments: comments(state.comments, action) } }
    case SET_POST_AUTH_DESTINATION:
      return { ...state, ...{ auth: { ...state.auth, ...{ destination: action.destination } } } }
    case OPEN_ACCORDION:
      return { ...state, ...{ accordion: { ...state.accordion, ...{ [action.name]: action.index } } } }
    case SET_FEED_DISPLAY:
      return {
        ...state,
        ...{
          feeds: {
            ...state.feeds,
            ...{ [action.feed]: { display: action.display } }
          }
        }
      }
    case TOGGLE_UI_ELEMENT:
      const newState =
        state && state.elements && state.elements[action.id] && state.elements[action.id].display !== undefined
          ? !state.elements[action.id].display
          : action.init
      return {
        ...state,
        ...{
          elements: {
            ...state.elements,
            ...{
              [action.id]: {
                ...(state.elements || {})[action.id],
                ...{ display: newState }
              }
            }
          }
        }
      }
    case SET_LOADING_STATE:
      return {
        ...state,
        ...{
          loaders: {
            ...state.loaders,
            ...{
              [action.id]: action.state
            }
          }
        }
      }
    default:
      return state
  }
}
