import { useSelector } from 'react-redux'
import { getAdminEventsTree } from '../../redux/user/state'
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const flatten = (input, eventID, indent = 0) => {
  return input
    .filter(event => event.id !== eventID)
    .reduce((agg, event) => {
      agg.push({
        id: event.id,
        label: `${'- '.repeat(indent)} ${event.name}`
      })
      if (Array.isArray(event.children)) {
        agg = agg.concat(flatten(event.children, eventID, indent + 1))
      }
      return agg
    }, [])
}

export const EventTypeSelector = props => {
  const { event: eventID, onChange, value, name = 'event', label = 'Event', id, size } = props

  const tree = useSelector(state => getAdminEventsTree(state))

  const [flattened, setFlattened] = useState(null)

  useEffect(() => {
    if (tree) {
      setFlattened(flatten(tree, eventID))
    }
  }, [tree])

  if (!flattened || !flattened.length) {
    return null
  }

  const selected = value ? [flattened.find(item => item.id === value)] : []

  return (
    <Form.Group controlId={id}>
      <Form.Label className="mt-2 mb-0">{label}</Form.Label>
      <Typeahead
        name={name}
        id={id}
        onChange={onChange}
        options={flattened}
        defaultSelected={selected}
        size={size}
      />
    </Form.Group>
  )
}

export default EventTypeSelector
