import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useRef, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlusSquare, faCode } from '@fortawesome/free-solid-svg-icons'

export default props => {
  const { entity, offers = [], id = 'OfferingOffersInput', name = 'offers', onChange } = props

  const inputRow = useRef(null)
  const groupRef = useRef(null)

  const handleChange = (e, index, name) => {
    const updated = [...offers]
    updated[index][name] = e.target.value
    if (onChange) {
      onChange(name, updated)
    }
  }

  const removeOffer = index => {
    const updated = [...offers]
    updated.splice(index, 1)
    if (onChange) {
      onChange(name, updated)
    }
  }

  const addOffer = (item = 0) => {
    const updated = [...offers]
    updated.push({
      code: inputRow.current.querySelectorAll(`input`).item(0).value,
      discount: inputRow.current.querySelectorAll(`input`).item(1).value,
      type: inputRow.current.querySelector(`select`).value
    })
    if (onChange) {
      onChange(name, updated)
    }

    setTimeout(() => {
      const index = groupRef.current.querySelectorAll(`input`).length + item - 4
      groupRef.current.querySelectorAll(`input`).item(index).focus()
    })
  }

  return (
    <Fragment>
      <Form.Label className="mt-2 mb-0 d-block">Offers</Form.Label>
      <Form.Text className="text-muted">Add offer codes and discounts for the ticket here.</Form.Text>
      <Form.Group
        ref={groupRef}
        controlId={`${id}`}>
        {(offers || []).map((offer, index) => (
          <Row
            key={index}
            className="mb-2">
            <Col xs={4}>
              <Form.Control
                onChange={e => handleChange(e, index, 'code')}
                placeholder="Offer code"
                value={offer.code}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a code</Form.Control.Feedback>
            </Col>
            <Col xs={2}>
              <Form.Control
                onChange={e => handleChange(e, index, 'discount')}
                placeholder="Discount"
                value={offer.discount}
                type="number"
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
            </Col>
            <Col xs={4}>
              <Form.Control
                as="select"
                onChange={e => handleChange(e, index, 'type')}
                value={offer.type}>
                <option value="absolute">Amount</option>
                <option value="percentage">Percentage</option>
              </Form.Control>
            </Col>
            <Col
              xs={2}
              className="text-end">
              <FontAwesomeIcon
                icon={faCode}
                title="Copy URL code"
                className="cursor-pointer mt-2 me-2 text-secondary"
                onClick={() => {
                  navigator.clipboard.writeText(`?entity=${entity}&offercode=${offer.code}`)
                }}
              />
              <FontAwesomeIcon
                icon={faTimesCircle}
                title="Delete offer code"
                className="cursor-pointer mt-2 text-secondary"
                onClick={e => removeOffer(index)}
              />
            </Col>
          </Row>
        ))}
        <Row
          key={offers.length}
          className="mb-2"
          ref={inputRow}>
          <Col xs={4}>
            <Form.Control
              onChange={e => addOffer(0)}
              placeholder="Offer code"
              type="text"
            />
          </Col>
          <Col xs={2}>
            <Form.Control
              onChange={e => addOffer(1)}
              placeholder="Discount"
              type="number"
            />
          </Col>
          <Col xs={4}>
            <Form.Control
              as="select"
              onChange={e => addOffer()}>
              <option value="absolute">Amount</option>
              <option value="percentage">Percentage</option>
            </Form.Control>
          </Col>
          <Col
            xs={2}
            className="text-end">
            <FontAwesomeIcon
              icon={faPlusSquare}
              className="cursor-pointer mt-2 text-secondary"
              onClick={e => addOffer()}
            />
          </Col>
        </Row>
      </Form.Group>
    </Fragment>
  )
}
