import './Variables.css'
import './sass/App.scss'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './redux/store'
import { handleAuthToken } from './redux/auth/actions'
import { fetchTokens } from './redux/token/actions'
import { BrowserRouter, useHistory } from 'react-router-dom'
import Routes from './Routes'

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

const App = ({ children }) => {
  const history = useHistory()
  const store = configureStore()

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        store.dispatch(fetchTokens(true))
      }
    })
    return function cleanup() {
      unlisten()
    }
  }, [])

  useEffect(() => {
    store.dispatch(handleAuthToken()).then(result => {
      if (!result) {
        store.dispatch(fetchTokens())
      }
    })
  }, [])

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
