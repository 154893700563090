import React from 'react'
import List from './List'
import GiftList from './GiftList'
import Accordion from 'react-bootstrap/Accordion'
import { openAccordion } from '../../redux/ui/actions'
import Totals from './Totals'
import LoginButton from '../auth/LoginButton'
import OfferCodeForm from './OfferCodeForm'
import Stripe from './Stripe'
import Shipping from './Shipping'
import MailingListOptIn from './MailingListOptIn'
import Address from './Address'
import Section from './Section'
import { useDispatch, useSelector } from 'react-redux'
import {
  cartRequiresShipping,
  cartShippingIsSelected,
  cartShippingIsUnavailable,
  getCartAddressId,
  getCartPublicStripeKey,
  getEntityCart,
  getGiftableItems
} from '../../redux/shopping/state'
import NextButton from './NextButton'
import Form from 'react-bootstrap/Form'
import { userIsAuthenticated } from '../../redux/user/state'

export default props => {
  const { id } = props
  const dispatch = useDispatch()

  const cart = useSelector(state => getEntityCart(state, id))
  const authenticated = useSelector(state => userIsAuthenticated(state))
  const accordionSection = useSelector(state => {
    const { ui } = state
    return ui && ui.accordion && ui.accordion.PaymentWindow ? ui.accordion.PaymentWindow : null
  })
  const publicKey = useSelector(state => (cart ? getCartPublicStripeKey(state, cart) : null))

  if (!cart) return <p>Your cart is empty</p>

  if (!publicKey) return <p>Loading . . .</p>

  const requiresShipping = cartRequiresShipping(cart)
  const shippingSelected = cartShippingIsSelected(cart)
  const shippingUnavailable = cartShippingIsUnavailable(cart)
  const billingAddress = getCartAddressId(cart, 'billing')
  const shippingAddress = getCartAddressId(cart, 'shipping')
  const giftable = getGiftableItems(cart)

  const getSection = (section, title, content, display = true) => {
    if (!content || !display) return null

    if (Array.isArray(content)) {
      for (let i = 0; i < content.length; i++) {
        if (!content[i]) {
          return null
        }
      }
    }

    const { index } = section
    const eventKey = String(index)
    section.index++

    return (
      <Section
        title={`${index + 1}. ${title}`}
        eventKey={eventKey}
        className="text-secondary">
        {content}
      </Section>
    )
  }

  const getRegistration = () => {
    if (authenticated) return null

    return (
      <h4>
        <LoginButton
          label="Login"
          theme="inline"
          className="text-primary me-1"
        />
        or
        <LoginButton
          label="Register"
          theme="inline"
          className="text-primary ms-1"
          hash="RegistrationForm"
        />
      </h4>
    )
  }

  const getStripeForm = () => {
    const onSuccess = () => {
      dispatch(openAccordion('PaymentWindow', null))
    }
    return (
      <Stripe
        id={id}
        onSuccess={onSuccess}
      />
    )
  }

  const section = { index: 0 }

  return (
    <Form
      novalidate="true"
      id="PaymentForm"
      className="bg-white py-1 pb-3">
      <Accordion defaultActiveKey={String(Number(accordionSection))}>
        {getSection(section, 'Your cart', [
          <List id={id} />,
          <OfferCodeForm
            entity={id}
            id="PaymentOfferCode"
            className="mb-3"
          />,
          <Totals id={id} />,
          <NextButton index={section.index + 1} />
        ])}

        {getSection(section, 'Sharing', [<GiftList id={id} />, <NextButton index={section.index + 1} />], giftable)}

        {getSection(section, 'Register', [getRegistration()], !authenticated)}

        {getSection(
          section,
          'Your ' + (requiresShipping ? 'billing ' : '') + 'address',
          [
            <Address
              id={id}
              type="billing"
            />,
            <NextButton
              index={section.index + 1}
              disabled={!billingAddress}
            />
          ],
          authenticated
        )}

        {getSection(
          section,
          'Your shipping address',
          [
            <Address
              id={id}
              type="shipping"
            />,
            <NextButton
              index={section.index + 1}
              disabled={!shippingAddress}
            />
          ],
          authenticated && requiresShipping
        )}

        {getSection(
          section,
          'Shipping',
          [
            <Shipping id={id} />,
            <NextButton
              index={section.index + 1}
              disabled={!shippingSelected || shippingUnavailable}
            />
          ],
          authenticated && requiresShipping
        )}
        {getSection(
          section,
          'Payment details',
          [<Totals id={id} />, getStripeForm(), <MailingListOptIn id={id} />],
          authenticated &&
            billingAddress &&
            (!requiresShipping || (shippingSelected && shippingAddress && !shippingUnavailable))
        )}
      </Accordion>
    </Form>
  )
}
