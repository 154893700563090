import React from 'react'
import Button from 'react-bootstrap/Button'
import TicketModal from './TicketModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEventOfferings, getEvent } from '../../redux/state'

export const TicketButton = props => {
  const dispatch = useDispatch()

  const {
    id,
    mode,
    className,
    size,
    includeParent = true,
    variant = 'light',
    theme = 'light',
    children,
    category = null,
    style = {}
  } = props

  const hasTickets = useSelector(state => {
    const offerings = getEventOfferings(state, id, includeParent)
    if (!offerings) return false
    if (category) return Boolean(offerings.filter(item => item.category === category).length)
    return Boolean(offerings.length)
  })

  const label = useSelector(state => {
    const event = getEvent(state, id)
    return event && event.feed && event.feed.ticket && event.feed.ticket.label ? event.feed.ticket.label : 'Buy tickets'
  })

  if (!hasTickets) {
    return null
  }

  const handleClick = e => {
    e.stopPropagation()
    dispatch(showModal('tickets-events', id, true))
    if (props.handleClick) {
      props.handleClick(e)
    }
  }

  switch (mode) {
    case 'icon':
      return (
        <>
          <button
            title={label}
            onClick={handleClick}
            className={className}>
            <FontAwesomeIcon icon={faTicketAlt} />
          </button>
          <TicketModal
            id={id}
            resource="events"
            includeParent={includeParent}
            theme={theme}
            category={category}
          />{' '}
        </>
      )
    case 'link':
      return (
        <>
          <button
            title={label}
            onClick={handleClick}
            className={className}
            style={style}>
            <span>{children || label}</span>
          </button>
          <TicketModal
            id={id}
            resource="events"
            includeParent={includeParent}
            theme={theme}
            category={category}
          />{' '}
        </>
      )

    default:
      return (
        <>
          <Button
            variant={variant}
            title={label}
            onClick={handleClick}
            className={className}
            size={size}>
            <FontAwesomeIcon
              icon={faTicketAlt}
              className="me-2"
            />
            <span className="text-nowrap">{children || label}</span>
          </Button>
          <TicketModal
            id={id}
            resource="events"
            includeParent={includeParent}
            theme={theme}
            category={category}
          />{' '}
        </>
      )
  }
}

export default TicketButton
