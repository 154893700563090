import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { getLoadingState } from '../../../redux/ui/state'
import Spinner from 'react-bootstrap/Spinner'

export const LineChart = props => {
  const { options, data, id, includeSubEvents } = props

  const isLoading = useSelector(state =>
    Boolean(getLoadingState(state, `event-sales-report-${id}${includeSubEvents ? '-subevents' : ''}`) === 'requested')
  )

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner variant="secondary" />
      </div>
    )

  if (!options || !data) return null

  return (
    <Line
      options={options}
      data={data}
    />
  )
}

export default LineChart
