import StreamEventCard from '../streamevent/Card'
import EventCard from '../event/Card'
import EntityCard from '../entity/Card'

export const FeedItem = props => {
  const { name, item } = props

  const { id } = item

  switch (item.__type) {
    case 'events':
      return (
        <EventCard
          id={id}
          feed={name}
        />
      )
    case 'entities':
      return (
        <EntityCard
          id={id}
          feed={name}
        />
      )
    default:
      return (
        <StreamEventCard
          id={id}
          feed={name}
        />
      )
  }
}

export default FeedItem
