import React, { useEffect } from 'react'
import $ from 'jquery'
import Feed from '../feed/Feed'
import Menu from '../navigation/Menu'
import Banner from './Banner'
import TagFilter from '../feed/TagFilter'
import SearchForm from '../feed/SearchForm'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEvent } from '../../redux/event/actions'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import ChatButton from '../chat/ChatButton'
import ChatPanel from '../chat/ChatPanel'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import ShareButton from '../social/ShareButton'
import EditButton from '../streamevent/EditButton'
import EventEditButton from './EditButton'
import TicketList from './TicketList'
import SponsorList from './SponsorList'
import { fetchUserAccessPasses } from '../../redux/access/actions'
import { fetchUserGifts } from '../../redux/shopping/actions'
import {
  getEventAncestors,
  getEvent,
  getEventEntity,
  isEventAdmin,
  getEventOfferings,
  getCurrentUser
} from '../../redux/state'
import { trackPageView, initGA } from '../tracking/Google'
import { trackPageView as fbPageView, initFBPixel } from '../tracking/Facebook'
import Breadcrumb from '../navigation/Breadcrumb'
import { ScriptLoader } from '../mailchimp/mailchimp'
import { filterFeed, setFeedOrder } from '../../redux/feed/actions'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { getEventFeedName } from '../../redux/event/state'
import TagFilterList from '../feed/TagFilterList'
import Sort from '../feed/Sort'

export const FeedWindow = props => {
  const { id: propId } = props

  const dispatch = useDispatch()

  const { id = propId } = useParams()

  const event = useSelector(state => getEvent(state, id))
  const entity = useSelector(state => getEventEntity(state, id))
  const ancestors = useSelector(state => getEventAncestors(state, id))
  // const tenant = useSelector(state => getTenantEvent(state))
  const user = useSelector(state => getCurrentUser(state))
  // const authenticated = useSelector(state => userIsAuthenticated(state))
  const administrator = useSelector(state => isEventAdmin(state, event?.id || id))
  const hasTickets = useSelector(state => Boolean(getEventOfferings(state, event?.id || id)))
  const feedName = useSelector(state => getEventFeedName(state, id))
  const feedHasContent = useSelector(state => {
    const { feeds } = state
    return feeds && feeds[feedName] && feeds[feedName].items && feeds[feedName].items.length
  })
  const tags = useSelector(state => {
    const { feeds } = state
    return feeds &&
      feeds[feedName] &&
      feeds[feedName] &&
      feeds &&
      feeds[feedName] &&
      feeds[feedName].filter &&
      feeds[feedName].filter.tags &&
      feeds[feedName].filter.tags.length
      ? feeds[feedName].filter.tags
      : null
  })

  const content =
    event && event.feed && event.feed && event.feed.content && event.feed.content.length
      ? event.feed.content
      : ['streamevents', 'events', 'sponsors']
  const comments = useSelector(state => {
    const { ui } = state
    return {
      visible:
        event &&
        ui.comments &&
        ui.comments.events &&
        ui.comments.events[event.id] &&
        ui.comments.events[event.id].visible
    }
  })

  const urlSort = new URL(document.location).searchParams.get('sort')
  const urlTags = new URL(document.location).searchParams.get('tags')

  useEffect(() => {
    initGA(window.gaTrackingCode)
  }, [])

  useEffect(() => {
    if (id) dispatch(fetchEvent(id, true))
  }, [id])

  useEffect(() => {
    if (user) {
      dispatch(fetchUserAccessPasses())
      dispatch(fetchUserGifts())
    }
  }, [user])

  useEffect(() => {
    if (event) {
      dispatch(setCurrentTenant('events', event.id))
      document.title = event.name
      trackPageView()
      dispatch(log('events', event.id, 'feed', { view: 'feed', name: event.name }))
      if (event.entity) {
        dispatch(fetchEntity(event.entity.id))
      }
    }
  }, [event])

  useEffect(() => {
    if (entity) {
      if (entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id) {
        initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
        fbPageView()
      }
      if (entity.mailinglist && entity.mailinglist.popup && entity.mailinglist.popup.src) {
        ScriptLoader(entity.mailinglist.popup.src)
      }
    }
  }, [entity])

  useEffect(() => {
    if (urlSort) {
      dispatch(setFeedOrder(feedName, urlSort))
    } else if (event && event.feed && event.feed.sort) {
      dispatch(setFeedOrder(feedName, event.feed.sort))
    } else {
      dispatch(setFeedOrder(feedName, '-rating'))
    }
  }, [urlSort, event])

  useEffect(() => {
    if (event) {
      const filter = urlTags ? { tags: urlTags.split(','), event: event.id } : { event: event.id }
      dispatch(filterFeed(feedName, filter, false))
    }
  }, [urlSort, urlTags, event])

  const renderBreadcrumb = () => {
    if (!ancestors || ancestors.length <= 1) {
      return null
    }

    const trail = ancestors.reverse().map(event => {
      return { path: '/events/' + (event.alias || event.id) + '/feed', label: event.name }
    })

    return (
      <Row>
        <Col className="pt-2 pb-1">
          <Breadcrumb
            trail={trail}
            className="mb-0"
          />
        </Col>
      </Row>
    )
  }

  const getAddButton = () => {
    return event && administrator ? (
      <React.Fragment>
        <EventEditButton
          id="new"
          event={event ? event.id : null}
          entity={event && event.entity ? event.entity.id : null}
          variant="light"
          className="ms-2"
        />
        <EditButton
          id="new"
          event={event ? event.id : null}
          variant="light"
          className="ms-3"
        />
      </React.Fragment>
    ) : null
  }

  const getTickets = () => {
    if (!content.includes('offerings') || !hasTickets || !event) {
      return null
    }
    const title =
      event && event.feed && event.feed.ticket && event.feed.ticket.label ? event.feed.ticket.label : 'Tickets'
    return (
      <Row className="border-bottom pt-2 pb-3 px-md-3">
        <Col
          className=""
          xs={12}>
          <h3>{title}</h3>
          <TicketList id={event.id} />
        </Col>
      </Row>
    )
  }

  const getSponsorList = () => {
    if (!event) {
      return null
    }

    const sponsors = (ancestors || []).reduce(
      (agg, current) => {
        if (agg && agg.length) return agg
        return current.feed &&
          current.feed.content &&
          current.feed.content.length &&
          current.feed.content.includes('sponsors')
          ? current.sponsors
          : null
      },
      content.includes('sponsors') ? event.sponsors : null
    )

    if (!sponsors || !sponsors.length) {
      return null
    }
    return (
      <Row className="border-bottom pt-2 pb-3 px-md-3">
        <Col
          className=""
          xs={12}>
          <SponsorList data={sponsors} />
        </Col>
      </Row>
    )
  }

  const handleChatClick = () => {
    $('html, body').animate(
      {
        scrollTop: $('#FeedRow').offset().top - $('.feed-header__controls').height() - 15 + 'px'
      },
      400
    )
  }

  const getComments = () => {
    return event.feed && event.feed.comments && comments.visible ? (
      <Col xs={6}>
        <ChatPanel
          resource="events"
          id={event.id}
          className="ps-2 ps-md-4"
        />
      </Col>
    ) : null
  }

  const getIntro = () => {
    return event.feed && event.feed.introduction ? (
      <Row className="pb-1">
        <Col className="pb-1 border-bottom bg-white">
          <TextBlock
            text={event.feed.introduction}
            className="m-2 feed-introduction"
          />
        </Col>
      </Row>
    ) : null
  }

  const getTagFilter = () => {
    return event.tags ? (
      <div className="bg-white mt-2">
        <div className="d-none d-md-block">
          <TagFilter
            tags={event.tags}
            feed={feedName}
            entity={entity}
          />
        </div>
        <TagFilterList
          tags={event.tags}
          feed={feedName}
          entity={entity}
          className="d-md-none"
        />
      </div>
    ) : null
  }

  if (!event) {
    return null
  }

  const filter = { event: event.id }
  if (tags) {
    filter.tags = tags
  }
  const split = event.feed && event.feed.split
  const grouping = event.feed ? event.feed.grouping : 'minute'

  const feedRowCss = comments.visible ? 'feed-content feed-content--comments g-0 px-3' : 'feed-content g-0 px-3'

  return (
    <React.Fragment>
      {event.feed && event.feed.css ? <style type="text/css">{event.feed.css}</style> : null}
      <Container className="bg-white feed-window">
        <Menu addButton={getAddButton()} />
        {renderBreadcrumb()}
        <Row>
          <Col className="pt-2 pb-2">
            <Banner id={event.id} />
          </Col>
        </Row>
        {getTickets()}
        <Row>
          <Col
            className="feed-header"
            xs={12}>
            {getIntro()}
            {getTagFilter()}
            <div className="feed-header__controls d-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
              {feedHasContent && content.includes('schedule') ? (
                <Sort
                  name={feedName}
                  className="me-3"
                />
              ) : null}
              <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                <SearchForm
                  feed={feedName}
                  className="text-end d-flex justify-content-between flex-grow-1"
                />
                <ShareButton
                  className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__share"
                  resource="events"
                  mode="icon"
                  id={event.id}
                  title="Share this event"
                />
                {event.feed && event.feed.comments ? (
                  <ChatButton
                    className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__chat"
                    resource="events"
                    mode="icon"
                    total={true}
                    id={event.id}
                    handleClick={handleChatClick}
                    toggle={true}
                    closeIcon="/images/icons/chevron-double-right.svg"
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <div className="feed-container">
          <Row
            id="FeedRow"
            className={feedRowCss}>
            <Col
              xs={6}
              md={comments.visible ? 6 : 12}>
              <Feed
                key={feedName}
                name={feedName}
                resource={content.filter(item => ['streamevents', 'events'].includes(item))}
                filter={filter}
                split={split}
                grouping={grouping}
                cols={comments.visible ? 1 : 2}
              />
            </Col>
            {getComments()}
          </Row>
        </div>
        {getSponsorList()}
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default FeedWindow
