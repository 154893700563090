import { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { fetchChildContent } from '../../../../../redux/content/actions'
import { getChildContent } from '../../../../../redux/content/state'
import ImageBackgroundContainer from '../../components/ImageBackgroundContainer'

const Tile = child => {
  const { navigation, title, alias, entity, id, preview } = child
  const entityAlias = entity && entity.alias ? entity.alias : entity.id

  const history = useHistory()
  const href = navigation && navigation.url ? navigation.url : `/entities/${entityAlias}/content/${alias || id}`
  const onClick = e => {
    if (href) {
      e.preventDefault()
      if (/^https?:\/\//.test(href)) {
        document.location = href
      } else {
        history.push(href)
      }
    }
  }

  const previewPara = preview?.text ? <p className="my-0">{preview.text}</p> : null

  return (
    <ImageBackgroundContainer
      key={child.id}
      id={`content-tile-${(child.alias || child.title).replace(/\W+/g, '-').toLowerCase()}`}
      content={child}
      className="w-100 d-flex flex-column justify-content-end vwh-50 vwh-md-25 cursor-pointer border border-dark border-1"
      href={href}
      onClick={onClick}>
      <div>
        <div className="fade-up-dark-65 bg-opacity-65 py-3">&nbsp;</div>
        <div className="bg-dark bg-opacity-65 cursor-grab p-3 theme__panorama__content">
          <h3 className="text-uppercase mb-0">{title}</h3>
          {previewPara}
        </div>
      </div>
    </ImageBackgroundContainer>
  )
}

export default props => {
  const dispatch = useDispatch()
  const { id, tags, entity } = props

  const children = useSelector(state => {
    return (getChildContent(state, 'content', id) || []).filter(
      item => item.visibility === 'public' && (!tags || tags.filter(tag => (item.tags || []).includes(tag)).length)
    )
  })

  useEffect(() => {
    dispatch(fetchChildContent('content', id))
  }, [id])

  if (!children || !children.length) return null

  return (
    <Row className="g-0">
      {children.map(child => (
        <Col
          key={child.id}
          xs={6}
          md={3}
          className="g-0">
          <Tile
            {...child}
            entity={entity}
          />
        </Col>
      ))}
    </Row>
  )
}
