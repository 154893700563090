export const getDataSet = (
  sales,
  event,
  type,
  cummulative,
  asComparisonData = false,
  colour = { r: 12, g: 109, b: 253 }
) => {
  if (!sales || !event) return null

  const key = (() => {
    switch (true) {
      case Boolean(type === 'admissions' && cummulative):
        return 'accAdmissions'
      case Boolean(type === 'admissions' && !cummulative):
        return 'admissions'
      case Boolean(type === 'sales' && cummulative):
        return 'accNet'
      default:
        return 'net'
    }
  })()

  let data = [...sales]

  const { datetime, duration } = event

  const endDate = new Date(new Date(datetime).getTime() + Math.max(0, duration) * 60000)

  if (asComparisonData) {
    data = data.filter(period => new Date(period.date.start) <= endDate)
  }

  return {
    label: `${event.name}`,
    data: data.map(period => ({
      x: asComparisonData
        ? Math.round((new Date(period.date.finish).getTime() - endDate.getTime()) / 604800000)
        : new Date(period.date.finish),
      y: period.sales[key]
    })),
    fill: true,
    backgroundColor: [`rgba(${colour.r}, ${colour.g}, ${colour.b}, 0.3)`],
    borderColor: [`rgb(${colour.r}, ${colour.g}, ${colour.b})`],
    tension: 0,
    borderJoinStyle: 'round',
    borderWidth: 2,
    pointRadius: function (context) {
      return 0
      const { dataIndex } = context
      return data[dataIndex - 1] && data[dataIndex - 1].sales[key] !== data[dataIndex].sales[key] ? 3 : 0
    },
    stepped: true
  }
}

export default getDataSet
