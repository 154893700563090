import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { getEventSalesSummary } from '../../../redux/event/state'
import Table from 'react-bootstrap/Table'
import { getEvent } from '../../../redux/state'
import { getElementState, getLoadingState } from '../../../redux/ui/state'
import Spinner from 'react-bootstrap/Spinner'

export const SalesReport = props => {
  const { id } = props

  const includeSubEvents = useSelector(state => getElementState(state, 'report-sales-chart-include-subevents', false))

  const pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  })

  const data = useSelector(state => {
    const data = getEventSalesSummary(state, includeSubEvents, id)
    if (!data) return null
    data.sales.items = data.sales.items.map(item => {
      const event = getEvent(state, item.id)
      if (!event)
        return {
          ...item,
          ...{ name: item.id }
        }
      return {
        ...item,
        ...{ name: event.name }
      }
    })
    return data
  })

  const isLoading = useSelector(state =>
    Boolean(getLoadingState(state, `event-sales-summary-${id}${includeSubEvents ? '-subevents' : ''}`) === 'requested')
  )

  if (isLoading)
    return (
      <div className="text-center pt-4">
        <Spinner variant="secondary" />
      </div>
    )

  if (!data) return null

  return (
    <>
      <h4>Summary</h4>
      <Table hover>
        <thead>
          <tr>
            <th>Event</th>
            <th className="text-end">Admissions</th>
            <th className="text-end">Quantity</th>
            <th className="text-end">Net</th>
            <th className="text-end">VAT</th>
            <th className="text-end">Total</th>
          </tr>
        </thead>
        <tbody className="list-unstyled">
          <tr className="fw-bold">
            <td>Total Sales</td>
            <td className="text-end">{data.sales.admissions}</td>
            <td className="text-end">{data.sales.total}</td>
            <td className="text-end">{pounds.format(data.sales.net)}</td>
            <td className="text-end">{pounds.format(data.sales.vat)}</td>
            <td className="text-end">{pounds.format(data.sales.net + data.sales.vat)}</td>
          </tr>
          {data.sales.items
            .sort((a, b) => b.quantity - a.quantity)
            .map((item, index) => (
              <Fragment key={index}>
                <tr key={`sale-${index}`}>
                  <td>{item.name}</td>
                  <td className="text-end">{item.admissions}</td>
                  <td className="text-end">{item.quantity}</td>
                  <td className="text-end">{pounds.format(item.net)}</td>
                  <td className="text-end">{pounds.format(item.vat)}</td>
                  <td className="text-end">{pounds.format(item.net + item.vat)}</td>
                </tr>
                {item.offerings
                  .sort((a, b) => b.quantity - a.quantity)
                  .map((offering, offeringIndex) => (
                    <tr
                      key={`sale-${index}-offering-${offeringIndex}`}
                      className="text-muted">
                      <td className="ps-4">{offering.name}</td>
                      <td className="text-end">{offering.admissions}</td>
                      <td className="text-end">{offering.quantity}</td>
                      <td className="text-end">{pounds.format(offering.net)}</td>
                      <td className="text-end">{pounds.format(offering.vat)}</td>
                      <td className="text-end">{pounds.format(offering.net + offering.vat)}</td>
                    </tr>
                  ))}
              </Fragment>
            ))}
        </tbody>
      </Table>
    </>
  )
}

export default SalesReport
