import { Accordion, Badge } from 'react-bootstrap'
import TicketListitem from './TicketListitem'
import { ListGroup } from 'react-bootstrap'
import ResendButton from '../access/ResendButton'

export default props => {
  const { data, index, entity } = props

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const filtered = (data.tickets || []).reduce((agg, current) => {
    const date = new Date(current.created).toLocaleDateString(undefined, dateOptions)
    if (agg[date]) {
      agg[date].push(current)
    } else {
      agg[date] = [current]
    }
    return agg
  }, {})

  if (!filtered || !Object.keys(filtered).length) return null

  return (
    <Accordion.Item eventKey={`${index}`}>
      <Accordion.Header>
        {data.name}
        <Badge
          className="ms-2"
          bg="success"
          pill>
          {data.tickets.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex justify-content-between">
          <div>
            <p>Email: {data.email}</p>
          </div>
          <ResendButton
            name={data.name}
            user={data.id}
            entity={entity}
            size="sm"
            variant="dark"
          />
        </div>
        <h5>Tickets</h5>
        {Object.keys(filtered).map(key => (
          <>
            <h6 className="mt-3">Purchased {key}</h6>
            <ListGroup>
              {filtered[key].map(ticket => (
                <TicketListitem
                  key={`${ticket.id}-${ticket.status}`}
                  data={ticket}
                />
              ))}
            </ListGroup>
          </>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  )
}
