import { useDispatch, useSelector } from 'react-redux'
import { getEventAccesses } from '../../redux/event/state'
import { fetchEventAccesses } from '../../redux/event/actions'
import { isEventAdmin } from '../../redux/state'
import ListGroup from 'react-bootstrap/ListGroup'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'
import GuestListItem from './GuestListItem'
import { Spinner } from 'react-bootstrap'
import { getLoadingState } from '../../redux/ui/state'

export default function GuestList(props) {
  const { id } = props
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')
  const [usedHidden, hideUsed] = useState(false)

  const accesses = useSelector(state => getEventAccesses(state, id))

  const authorised = useSelector(state => isEventAdmin(state, id))

  const isLoading = useSelector(state => Boolean(getLoadingState(state, `event-accesses-${id}`) === 'requested'))

  useEffect(() => {
    dispatch(fetchEventAccesses(id, true))
  }, [id])

  if (!authorised) return null

  if (isLoading)
    return (
      <div className="text-center py-4">
        <Spinner variant="secondary" />
      </div>
    )

  if (!accesses || !accesses.length) return <p>No passes found.</p>

  return (
    <>
      <div className="input-group mb-3 align-items-center">
        <span className="me-3 fw-bold">
          {accesses.length.toLocaleString()} pass{accesses.length > 1 ? 'es' : ''}
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <button
          type="button"
          className="btn bg-transparent text-secondary"
          style={{ marginLeft: '-40px', zIndex: 100 }}
          onClick={() => setSearch('')}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Hide used passes"
          className="ms-3"
          checked={usedHidden}
          onChange={e => hideUsed(e.target.checked)}
        />
      </div>{' '}
      <ListGroup>
        {accesses
          .filter(access => access.owner)
          .filter(access => !usedHidden || access.status === 'unused')
          .filter(access => !search || access.owner.name.match(new RegExp(search, 'gi')))
          .sort((a, b) => a.owner.name.localeCompare(b.owner.name))
          .map((access, index) => (
            <GuestListItem
              access={access}
              key={`${access.id}-${index}`}
            />
          ))}
      </ListGroup>
    </>
  )
}
