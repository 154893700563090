/**
 * UI
 */
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS'
export const SHOW_MODAL = 'SHOW_MODAL'
export const SET_LOGIN_MODAL_POSITION = 'SET_LOGIN_MODAL_POSITION'
export const OPEN_ACCORDION = 'OPEN_ACCORDION'
export const PLAYER_FULLSCREEN = 'PLAYER_FULLSCREEN'
export const SHOW_COMMENTS = 'SHOW_COMMENTS'
export const SHOW_COMMENT_DELETE_MODAL = 'SHOW_COMMENT_DELETE_MODAL'
export const SET_CURRENT_TENANT = 'SET_CURRENT_TENANT'
export const SET_FEED_DISPLAY = 'SET_FEED_DISPLAY'
export const TOGGLE_UI_ELEMENT = 'TOGGLE_UI_ELEMENT'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'

export const hideAllModals = () => {
  return {
    type: HIDE_ALL_MODALS
  }
}

export const hideModalsAndShow = (modal, ...args) => {
  return (dispatch, getState) => {
    dispatch(hideAllModals())
    dispatch(showModal(modal, ...args))
  }
}

export const showModal = (modal, ...args) => {
  const visible = args.pop()
  const id = args.length ? args.pop() : null
  const action = args.length ? args.shift() : null
  return {
    type: SHOW_MODAL,
    modal,
    visible,
    id,
    action
  }
}

export const showCommentDeleteModal = (visible, id) => {
  return {
    type: SHOW_COMMENT_DELETE_MODAL,
    visible,
    id
  }
}

export const setLoginModalPosition = index => {
  return {
    type: SET_LOGIN_MODAL_POSITION,
    index
  }
}

export const playerFullscreen = fullscreen => {
  return {
    type: PLAYER_FULLSCREEN,
    fullscreen
  }
}

export const showComments = (resource, id, visible) => {
  return {
    type: SHOW_COMMENTS,
    resource,
    id,
    visible
  }
}

export const setCurrentTenant = (resource, id) => {
  return {
    type: SET_CURRENT_TENANT,
    resource,
    id
  }
}

export const setFeedDisplay = (feed, display) => {
  return {
    type: SET_FEED_DISPLAY,
    feed,
    display
  }
}

export const openAccordion = (name, index) => {
  return {
    type: OPEN_ACCORDION,
    name,
    index
  }
}

export const toggleUIElement = (id, init = true) => {
  return {
    type: TOGGLE_UI_ELEMENT,
    id,
    init
  }
}

export const setLoadingState = (id, state) => {
  return {
    type: SET_LOADING_STATE,
    id,
    state
  }
}
