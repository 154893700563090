import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from '../../../redux/ui/actions'
import { modalIsVisible } from '../../../redux/ui/state'
import SaleForm from './SaleForm'
import { getEvent } from '../../../redux/state'

export const BoxOfficeSaleModal = props => {
  const { offering } = props
  const dispatch = useDispatch()

  const show = useSelector(state =>
    modalIsVisible(state, `boxoffice-sale-events`, `${offering?.resource?.id}-${offering?.id}`)
  )

  const event = useSelector(state => getEvent(state, offering?.resource?.id))

  const handleClose = () => {
    dispatch(showModal(`boxoffice-sale-events`, `${offering?.resource?.id}-${offering?.id}`, false))
  }

  if (!offering) return null

  return (
    <Modal
      show={show}
      aria-labelledby="boxoffice-sale-modal"
      centered
      onHide={handleClose}
      size="lg"
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title id="boxoffice-sale-modal">
          {event?.name} - {offering.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SaleForm offering={offering} />
      </Modal.Body>
    </Modal>
  )
}

export default BoxOfficeSaleModal
