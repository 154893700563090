import requiredServices from '../../config/services'
import { fetchCurrentUser, clearCurrentUser, fetchPlatformSupportRole } from '../user/actions'
import { fetchAdministratorEvents, fetchAdoptiveEvents } from '../event/actions'
import { fetchAdministratorEntities, fetchOwnedEntities, fetchUserEntities } from '../entity/actions'
import { clearCarts } from '../shopping/actions'
import { fetchUserGiftables } from '../giftable/actions'
import { clearUserAccessPasses } from '../access/actions'
import { fetchAdministratorVenues } from '../venue/actions'

/**
 * Get tokens
 * Load stream events
 * Load stream event
 * Upload picture
 * Save stream event
 * Load user
 * Save user
 * Show player
 * Show feed
 *
 */

/**
 * TOKENS
 */
export const SEND_TOKEN = 'SEND_TOKEN'
export const REQUEST_TOKEN = 'REQUEST_TOKEN'
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'
export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR'
export const REQUEST_TOKEN_DELETION = 'REQUEST_TOKEN_DELETION'
export const RECEIVE_TOKEN_DELETION = 'RECEIVE_TOKEN_DELETION'
export const DELETE_TOKEN_ERROR = 'DELETE_TOKEN_ERROR'
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'

export const sendToken = (service, data) => {
  return {
    type: SEND_TOKEN,
    service,
    data
  }
}

export const requestToken = service => {
  return {
    type: REQUEST_TOKEN,
    service
  }
}

export const receiveToken = (service, id) => {
  return {
    type: RECEIVE_TOKEN,
    service,
    id
  }
}

export const fetchTokenError = (service, err) => {
  return {
    type: FETCH_TOKEN_ERROR,
    service,
    error: err
  }
}

export const requestTokenDeletion = service => {
  return {
    type: REQUEST_TOKEN_DELETION,
    service
  }
}

export const receiveTokenDeletion = service => {
  return {
    type: RECEIVE_TOKEN_DELETION,
    service
  }
}

export const deleteTokenError = (service, err) => {
  return {
    type: DELETE_TOKEN_ERROR,
    service,
    error: err
  }
}

const putToken = (dispatch, getState, service, data) => {
  dispatch(sendToken(service, data))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: data }),
    credentials: 'include'
  }
  return fetch(window.endpoint[service] + '/zauth-token', requestOptions)
    .then(response => {
      if (response.headers.get('authentication-info')) {
        localStorage.setItem(service + 'ApiToken', response.headers.get('authentication-info'))
      }
      return dispatch(receiveToken(service, data.user_id))
    })
    .catch(err => dispatch(fetchTokenError(service, err)))
}

export const putTokens = data => {
  return (dispatch, getState) => {
    const fetchArray = requiredServices.map(service => {
      return () => {
        return putToken(dispatch, getState, service, data)
      }
    })
    return fetchArray.reduce((prev, cur) => prev.then(cur), Promise.resolve())
  }
}

const fetchToken = (dispatch, getState, service, refresh = false) => {
  if (
    !refresh &&
    getState().tokens &&
    getState().tokens[service] &&
    getState().tokens[service].status !== 'initialised'
  ) {
    return true
  }
  dispatch(requestToken(service))
  const requestOptions = {
    method: 'GET',
    credentials: 'include'
  }
  const auth = localStorage.getItem(service + 'ApiToken')
  if (auth) requestOptions.headers = { 'Authentication-Info': auth }
  return fetch(window.endpoint[service] + '/zauth-token', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(service + ' token not available')
      }
      return response
    })
    .then(response => response.text())
    .then(id => dispatch(receiveToken(service, id)))
    .then(result => {
      if (result.service === 'users') {
        return dispatch(fetchCurrentUser(result.id, true))
      }
      return result
    })
    .catch(err => {
      dispatch(fetchTokenError(service, err))
      throw err
    })
}

export const fetchTokens = (refresh = false) => {
  return (dispatch, getState) => {
    const fetchArray = requiredServices.map(service => {
      return () => {
        return fetchToken(dispatch, getState, service, refresh)
      }
    })
    return fetchArray
      .reduce((prev, cur) => prev.then(cur), Promise.resolve())
      .then(result => {
        dispatch(fetchAdministratorEvents(true))
        dispatch(fetchAdoptiveEvents(true))
        dispatch(fetchAdministratorEntities(true))
        dispatch(fetchAdministratorVenues(true))
        dispatch(fetchOwnedEntities(true))
        dispatch(fetchUserEntities(true))
        dispatch(fetchPlatformSupportRole())
        dispatch(fetchUserGiftables())
      })
      .catch(err => {
        console.log(`Error fetching token(s) - logging out - ${err.message}`)
        dispatch(deleteTokens(requiredServices, false))
      })
  }
}

const deleteToken = (dispatch, service) => {
  dispatch(requestTokenDeletion(service))
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include'
  }
  const auth = localStorage.getItem(service + 'ApiToken')
  if (auth) requestOptions.headers = { 'Authentication-Info': auth }
  fetch(window.endpoint[service] + '/zauth-token', requestOptions)
    .then(() => {
      localStorage.removeItem(service + 'ApiToken')
      return dispatch(receiveTokenDeletion(service))
    })
    .catch(err => {
      console.log(err.message)
    })
}

export const deleteTokens = (services = requiredServices, carts = true) => {
  return (dispatch, getState) => {
    dispatch(clearCurrentUser())
    if (carts) {
      dispatch(clearCarts())
    }
    dispatch(clearUserAccessPasses())
    services = Array.isArray(services) ? services : [services]
    services.map(service => deleteToken(dispatch, service))
  }
}
