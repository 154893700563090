import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTicketedEvents } from '../../../redux/event/actions'
import { getTicketedEvents } from '../../../redux/event/state'
import { getLoadingState } from '../../../redux/ui/state'
import Spinner from 'react-bootstrap/Spinner'
import { isEventAdmin } from '../../../redux/state'
import BoxOfficeEvent from './BoxOfficeEvent'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'

export const BoxOffice = props => {
  const { id } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTicketedEvents(id, true))
  }, [id])

  const [search, setSearch] = useState('')
  const [pastEventsHidden, hidePastEvents] = useState(false)

  const reload = () => dispatch(fetchTicketedEvents(id, true))

  const ticketed = useSelector(state => getTicketedEvents(state, id))

  const isLoading = useSelector(state => Boolean(getLoadingState(state, `event-ticketed-events-${id}`) === 'requested'))

  const authorised = useSelector(state => isEventAdmin(state, id))

  if (!authorised) return <p>Unauthorised</p>

  if (isLoading)
    return (
      <div className="text-center py-4">
        <Spinner variant="secondary" />
      </div>
    )

  if (!ticketed || !ticketed.length) return <p>No ticketed events found</p>

  const now = new Date()

  return (
    <>
      <div className="input-group mb-3 align-items-center">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <button
          type="button"
          className="btn bg-transparent text-secondary"
          style={{ marginLeft: '-40px', zIndex: 100 }}
          onClick={() => setSearch('')}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Hide past events"
          className="ms-3"
          checked={pastEventsHidden}
          onChange={e => hidePastEvents(e.target.checked)}
        />
      </div>
      <Accordion defaultActiveKey="0">
        {ticketed
          .filter(event => !search || event.name.match(new RegExp(`${search}`, 'gi')))
          .filter(event => !pastEventsHidden || new Date(event.datetime) >= now)
          .sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())
          .map((event, index) => (
            <BoxOfficeEvent
              key={event.id}
              index={index}
              event={event}
            />
          ))}
      </Accordion>
    </>
  )
}

export default BoxOffice
