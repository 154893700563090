import { getContentMainImage } from '../../../../redux/content/state'
import Carousel from 'react-bootstrap/Carousel'
import './ImageBackgroundContainer.scss'
import { getVideoMimeType } from '../../../../common/VideoMimeTypes'
import { encodeRemoteFileName } from '../../../../redux/media/state'

const CarouselElement = props => {
  const { images } = props
  if (!images) return null
  return (
    <Carousel
      fade
      controls={false}
      indicators={false}
      className="position-absolute w-100 h-100 ">
      {images.map((image, index) => (
        <Carousel.Item
          className="w-100 h-100 background-image-cover"
          interval="6000"
          key={`carousel-${index}`}
          style={{ backgroundImage: 'url(' + image + ')' }}></Carousel.Item>
      ))}
    </Carousel>
  )
}

const VideoElement = content => {
  const { video } = content
  if (!video) return null
  const image = getContentMainImage(content)

  return (
    <video
      autoPlay="autoplay"
      loop="1"
      muted="1"
      className="position-absolute w-100 h-100 object-fit-cover top-0 bottom-0 start-0 end-0"
      poster={image}>
      <source
        src={encodeRemoteFileName(video)}
        type={getVideoMimeType(video)}
      />
    </video>
  )
}

export default props => {
  const { content, className, as = 'div', children, onClick, isCarousel, id, href } = props
  const As = as
  const image = getContentMainImage(content)
  const carousel =
    isCarousel !== undefined && content.images && content.images.length ? content.images.map(image => image.url) : null

  const carouselModifier = carousel ? 'carousel__' : ''
  const positionClass = carousel ? 'position-relative' : ''
  const { video } = content

  return (
    <As
      id={id}
      className={`background-image-cover ${positionClass} dynamic__content__${carouselModifier}container`}
      style={{ backgroundImage: 'url(' + image + ')' }}
      onClick={onClick}
      href={href}>
      {video ? <VideoElement {...content} /> : <CarouselElement images={carousel} />}
      <div className={`${positionClass} dynamic__content__${carouselModifier}content ${className}`}>{children}</div>
    </As>
  )
}
