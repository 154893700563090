import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import LoginButton from '../auth/LoginButton'
import LoginModal from '../auth/LoginModal'
import Avatar from '../user/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getTenantEntity,
  getTenantEvent,
  getTenantCart,
  getPaymentEntity,
  getEntityLogo,
  isEventAdmin
} from '../../redux/state'
import { fetchUserCarts } from '../../redux/shopping/actions'
import { fetchCurrentUser } from '../../redux/user/actions'
import SettingsButton from '../user/SettingsButton'
import { isPlatformSupport } from '../../redux/user/state'
import CartButton from '../cart/CartButton'

export const Menu = props => {
  const { addButton } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCurrentUser()).then(() => {
      dispatch(fetchUserCarts(true))
    })
  }, [])

  const event = useSelector(state => getTenantEvent(state))
  const tenant = useSelector(state => getTenantEntity(state))
  const administrator = useSelector(state =>
    event ? isPlatformSupport(state) || isEventAdmin(state, event.id) : false
  )
  const logo = useSelector(state => (tenant ? getEntityLogo(state, tenant.id) : ''))
  const user = useSelector(state => (state?.user?.id ? state.user : null))
  const settings = user && administrator

  const getSettingsButton = () => {
    return settings ? (
      <NavDropdown.Item className="dropdown-item px-3 ">
        <SettingsButton className="text-decoration-none text-gray-600 text-gray-900-hover" />
      </NavDropdown.Item>
    ) : null
  }

  const getLogoLink = () => {
    const logoElement = (
      <React.Fragment>
        <img
          className="d-none d-md-inline menu__logo"
          src={logo || '/images/zarucchi-logo-black.svg'}
          alt="Zarucchi"
        />
        <img
          className="d-md-none menu__logo menu__logo--small"
          src={logo || '/images/zarucchi-icon-black.svg'}
          alt="Zarucchi"
        />
      </React.Fragment>
    )
    return tenant ? (
      <Link
        to={'/entities/' + (tenant.alias || tenant.id) + (tenant.homepage ? '/content/' + tenant.homepage : '/feed')}>
        {logoElement}
      </Link>
    ) : (
      <React.Fragment>{logoElement}</React.Fragment>
    )
  }

  const getUserNav = () => {
    const avatar = <Avatar id={user ? user.id : ''} />
    return user ? (
      <NavDropdown
        title={avatar}
        id="basic-nav-dropdown"
        className="ms-3 justify-content-end">
        {/* <Nav.Item className="dropdown-item px-3">
          <Link to={ '/users/' + user.id + '/feed'}>Your Channel</Link>
        </Nav.Item> */}
        <NavDropdown.Item className="dropdown-item px-3">
          <Link
            to={'/users/' + user.id + '/tickets'}
            className="text-decoration-none text-gray-600 text-gray-900-hover">
            Your Tickets
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item className="dropdown-item px-3">
          <Link
            to={'/users/' + user.id + '/orders'}
            className="text-decoration-none text-gray-600 text-gray-900-hover">
            Your Orders
          </Link>
        </NavDropdown.Item>
        {getSettingsButton()}
        <NavDropdown.Divider />
        <NavDropdown.Item className="dropdown-item px-3">
          <LoginButton
            variant="outline-secondary"
            size="sm"
            className="text-nowrap"
          />
        </NavDropdown.Item>
      </NavDropdown>
    ) : (
      <LoginButton
        variant="outline-secondary"
        className="text-nowrap ms-3"
      />
    )
  }

  return (
    <React.Fragment>
      <Container
        fluid
        className="bg-white menu">
        <div className="d-flex justify-content-between align-items-center mx-n3 pt-2">
          <div className="me-auto text-start flex-grow-1">{getLogoLink()}</div>
          <div className="text-end d-flex justify-content-end align-items-center">
            {addButton}
            <CartButton className="text-secondary ms-3" />
            {getUserNav()}
          </div>
        </div>
      </Container>
      <LoginModal />
    </React.Fragment>
  )
}

export default Menu
