import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { useHistory } from 'react-router'
import { showModal } from '../../redux/ui/actions'
import { getEventPaymentEntity, getEventOfferings, isEventAdmin } from '../../redux/state'
import Countdown from '../cart/Countdown'
import { getEventOfferingCategories } from '../../redux/event/state'

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id, includeParent = true, category = null } = props

  const data = useSelector(state => {
    const offerings = getEventOfferings(state, id, includeParent)
    if (!offerings) return null
    if (category) return offerings.filter(item => item.category === category)
    return offerings
  })

  const isAdmin = useSelector(state => isEventAdmin(state, id))

  const entity = useSelector(state => getEventPaymentEntity(state, id))

  const categories = useSelector(state => getEventOfferingCategories(state, id))

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('tickets-events', id, false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  if (!data || !data.length || !entity) {
    return null
  }

  let categoryMap = (categories || []).reduce((agg, cat) => {
    agg[cat] = []
    return agg
  }, {})
  categoryMap._uncategorised = []

  data.forEach(item => {
    if (!item.active) return
    if (item.category) {
      if (!categoryMap[item.category]) {
        categoryMap[item.category] = [item]
      } else {
        categoryMap[item.category].push(item)
      }
    } else {
      categoryMap._uncategorised.push(item)
    }
  })

  categoryMap = Object.keys(categoryMap).reduce((map, key) => {
    if (categoryMap[key].length) map[key] = categoryMap[key]
    return map
  }, {})

  const showTitles = Object.keys(categoryMap).length > 1

  return (
    <div>
      {Object.keys(categoryMap).map((key, index) => {
        return (
          <>
            {showTitles ? (
              key === '_uncategorised' ? (
                <hr />
              ) : (
                <h5 className={`mt-${index === 0 ? '0' : '3'} fw-normal`}>{key}</h5>
              )
            ) : (
              ''
            )}

            {categoryMap[key].map((item, index) => {
              return (
                <Purchase
                  key={index}
                  offering={item}
                  className="mb-2"
                  isAdmin={isAdmin}
                />
              )
            })}
          </>
        )
      })}
      <div className="text-end">
        <Countdown
          className="text-secondary me-2 mt-1"
          id={entity.id}
        />
        <CheckoutButton
          className="mt-1 btn-block btn-md-inline"
          handleClick={checkout}
        />
      </div>
    </div>
  )
}
