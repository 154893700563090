import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEvent } from '../../../../../redux/event/actions'
import { getEvent } from '../../../../../redux/state'
import TicketButton from '../../../../event/TicketButton'

export default props => {
  const dispatch = useDispatch()
  const { entity, content, className, label = 'Tickets' } = props

  const featuredEvent = content.featuredEvent || entity.featuredEvent || null

  const ticketCategory = content.ticketCategory || null

  const event = useSelector(state => (featuredEvent ? getEvent(state, featuredEvent) : null))

  const { colors } = entity

  useEffect(() => {
    if (featuredEvent) {
      dispatch(fetchEvent(featuredEvent))
    }
  }, [featuredEvent])

  if (!event) return null

  const bgCol = colors && colors.primary ? colors.primary : '--bs-primary'

  return (
    <TicketButton
      className={`text-white fw-bold text-uppercase text-decoration-none ${className}`}
      style={{ backgroundColor: bgCol }}
      mode="link"
      id={featuredEvent}
      category={ticketCategory}
      theme="dark">
      {label}
    </TicketButton>
  )
}
