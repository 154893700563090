import { useDispatch, useSelector } from 'react-redux'
import { getEvent, isEventAdmin } from '../../../redux/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import BoxOfficeSaleModal from './SaleModal'
import { showModal } from '../../../redux/ui/actions'

export default function SaleButton(props) {
  const { offering, mode, children, label, variant = 'secondary', className, size } = props

  const dispatch = useDispatch()

  const event = useSelector(state => getEvent(state, offering?.resource?.id))

  const authorised = useSelector(state => isEventAdmin(state, event?.id))

  if (!authorised || !offering) return null

  const onClick = e => {
    e.stopPropagation()
    dispatch(showModal('boxoffice-sale-events', `${offering?.resource?.id}-${offering?.id}`, true))
  }
  let title = label || children || 'Cash sale'
  let disabled = false

  if (offering.stock !== undefined && offering.stock !== null && offering.stock <= 0) return null

  if (mode === 'icon') {
    return (
      <>
        <button
          title={title}
          onClick={onClick}
          className={className}
          disabled={disabled}>
          <FontAwesomeIcon icon={faCoins} />
        </button>
        <BoxOfficeSaleModal offering={offering} />
      </>
    )
  }

  return (
    <>
      <Button
        variant={variant}
        title={title}
        onClick={onClick}
        className={className}
        size={size}
        disabled={disabled}>
        <FontAwesomeIcon
          icon={faCoins}
          className="d-md-none"
        />
        <span className="d-none d-md-inline text-nowrap">{title}</span>
      </Button>
      <BoxOfficeSaleModal offering={offering} />
    </>
  )
}
