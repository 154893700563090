import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import { getDataObject } from '../../redux/state'
import { modalIsVisible } from '../../redux/ui/state'
import BoxOffice from './box-office/BoxOffice'

export const BoxOfficeModal = props => {
  const { resource, id } = props
  const dispatch = useDispatch()

  const data = useSelector(state => getDataObject(state, resource, id))

  const show = useSelector(state => modalIsVisible(state, `boxoffice-${resource}`, id))

  if (!data) return null

  const handleClose = () => {
    dispatch(showModal(`boxoffice-${resource}`, id, false))
  }

  return (
    <Modal
      show={show}
      aria-labelledby="boxoffice-modal"
      centered
      onHide={handleClose}
      size="xl"
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title id="boxoffice-modal">{data.name} Box Office</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BoxOffice id={id} />
      </Modal.Body>
    </Modal>
  )
}

export default BoxOfficeModal
