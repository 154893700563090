import { fetchEntity } from '../entity/actions'
import { post, patch, get, getMany, remove } from '../crud/actions'
import { receiveUserResources } from '../user/actions'
import { reloadFeed } from '../feed/actions'
import { fetchEvent } from '../event/actions'

/**
 * Contents
 */

const reloadParentFeed = result => {
  return (dispatch, getState) => {
    if (
      result &&
      result.data &&
      result.data.relationships &&
      result.data.relationships.parent &&
      result.data.relationships.parent.data &&
      result.data.relationships.parent.data.id
    ) {
      dispatch(reloadFeed(`${result.data.relationships.parent.data.type}-${result.data.relationships.parent.data.id}`))
    }
  }
}

const loadParent = result => {
  return (dispatch, getState) => {
    if (
      result &&
      result.data &&
      result.data.attributes &&
      result.data.attributes.parent &&
      result.data.attributes.parent.id &&
      result.data.attributes.parent.id !== 'undefined'
    ) {
      switch (result.data.attributes.parent.type) {
        case 'events':
          return dispatch(fetchEvent(result.data.attributes.parent.id))
        case 'content':
          return dispatch(fetchContent(result.data.attributes.parent.id))
      }
    }
    return null
  }
}

export const postContent = (entity, data) => {
  return (dispatch, getState) => {
    return dispatch(post('content', data, { path: `/entities/${entity}/content` }))
      .then(result => {
        dispatch(reloadParentFeed(result))
        return result
      })
      .then(result => {
        dispatch(fetchAdministratorContent(entity, true))
        return result
      })
  }
}

export const patchContent = data => {
  return (dispatch, getState) => {
    const currentEntity =
      getState().content && getState().content[data.id] && getState().content[data.id].entity
        ? getState().content[data.id].entity.id
        : null
    return dispatch(patch('content', data))
      .then(result => {
        dispatch(reloadParentFeed(result))
        return result
      })
      .then(result => {
        //dispatch(fetchAdministratorContent(true))
        return result
      })
  }
}

export const fetchContent = (entity, id, reload = false) => {
  return (dispatch, getState) => {
    if (!entity || !id) return
    return dispatch(get('content', id, { path: `/entities/${entity}/content/${id}`, reload })).then(result => {
      if (
        result &&
        result.data &&
        result.data.relationships &&
        result.data.relationships.entity &&
        result.data.relationships.entity.data &&
        result.data.relationships.entity.data.id
      ) {
        dispatch(fetchEntity(result.data.relationships.entity.data.id))
      }
      dispatch(loadParent(result))
      return result
    })
  }
}

export const fetchChildContent = (type, id) => {
  return dispatch => {
    const path = `/${type}/${id}/content`

    return dispatch(getMany('content', { path }))
  }
}

export const deleteContent = id => {
  return (dispatch, getState) => {
    const currentEntity =
      getState().content && getState().content[id] && getState().content[id].entity
        ? getState().content[id].entity.id
        : null
    return dispatch(remove('content', id))
      .then(result => {
        if (currentEntity) {
          return dispatch(fetchAdministratorContent(currentEntity, true))
        }
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchAdministratorContent = (entity, reload = false) => {
  return (dispatch, getState) => {
    if (!reload && getState().user && getState().user.administrator && getState().user.administrator.content) {
      return Promise.resolve(getState().user.administrator.content)
    }
    if (!getState().user || !getState().user.id) {
      return Promise.resolve(null)
    }

    const path = '/entities/' + entity + '/users/' + getState().user.id + '/roles/administrator/content'

    return dispatch(getMany('content', { path })).then(result => {
      if (result && result.data) {
        dispatch(receiveUserResources('content', result.data, 'administrator', entity))
        //result.data.forEach(event => dispatch(fetchContent(event.id, true)))
      }
      return result
    })
  }
}

export const reloadContentFeed = id => {
  return (dispatch, getState) => {
    const { content } = getState()
    if (content[id]) {
      dispatch(reloadFeed('content-' + (content[id].alias || content[id].id)))
    }
  }
}
