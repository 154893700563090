import React, { useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13
}

const delimiters = [KeyCodes.tab, KeyCodes.comma, KeyCodes.enter]

export const TagSelector = props => {
  const { tags, suggestions, handleChange } = props

  const [storedTags, setStoredTags] = useState(tags || [])
  const [storedSuggestions, setStoredSuggestions] = useState(suggestions || [])

  const handleDelete = i => {
    let tags = [...storedTags]
    tags = tags.filter((tag, index) => index !== i)
    setStoredTags(tags)
    if (handleChange) {
      handleChange(tags)
    }
  }

  const handleAddition = tag => {
    let tags = [...storedTags, tag]
    tags = tags.filter((v, i, a) => a.indexOf(v) === i)
    setStoredTags(tags)
    if (handleChange) {
      handleChange(tags)
    }
  }

  const handleDrag = (tag, currPos, newPos) => {
    const tags = [...storedTags]

    tags.splice(currPos, 1)
    tags.splice(newPos, 0, tag)

    // re-render
    setStoredTags(tags)
    if (handleChange) {
      handleChange(tags)
    }
  }

  return (
    <ReactTags
      tags={storedTags}
      suggestions={storedSuggestions}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      delimiters={delimiters}
      classNames={{
        tag: 'btn btn-light btn-sm me-1 mb-1',
        tagInputField: 'form-control mt-1',
        remove: 'ms-2 font-weight-bold cursor-pointer',
        suggestions: 'tag-suggestions mt-2',
        activeSuggestion: 'text-primary'
      }}
    />
  )
}

export default TagSelector
