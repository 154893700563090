import { useState } from 'react'

export const useFormGetSet = (state = {}, onChange = null) => {
  const [data, setData] = useState(state)

  const pathFromString = string => {
    if (string.indexOf('.') !== -1) return string.split('.')
    return string.split('_')
  }

  const setValues = (keyPair = {}) => {
    const dataObject = Object.keys(keyPair).reduce(
      (agg, name) => {
        const value = keyPair[name]
        const path = pathFromString(name)
        let pointer = agg
        path.forEach((name, i) => {
          pointer[name] = i + 1 === path.length ? value : pointer[name] || {}
          pointer = pointer[name]
        })
        return agg
      },
      { ...data }
    )
    setData(dataObject)
    if (onChange) onChange(dataObject)
    return dataObject
  }

  const setValue = (name, value) => {
    const path = pathFromString(name)
    const dataObject = { ...data }
    let pointer = dataObject
    path.forEach((name, i) => {
      pointer[name] = i + 1 === path.length ? value : pointer[name] || {}
      pointer = pointer[name]
    })
    setData(dataObject)
    if (onChange) onChange(dataObject)
    return dataObject
  }

  const getValue = name => {
    if (!data) {
      return ''
    }
    const path = pathFromString(name)
    let pointer = data,
      item
    while (undefined !== (item = path.shift())) {
      if (!path.length) {
        return pointer[item] || ''
      }
      if (!pointer[item]) {
        return ''
      }
      pointer = pointer[item]
    }
    return ''
  }

  return [data, setData, getValue, setValue, setValues]
}

export default useFormGetSet
