import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TagFilterButton = props => {
  const { selected, handleChange, label, className, selectedClassName } = props

  const onClick = () => {
    const updated = !selected
    if (handleChange) {
      handleChange(label, updated)
    }
  }

  const btnClass = selected ? selectedClassName || 'btn btn-dark me-2 mb-1' : className || 'btn btn-light me-2 mb-1'

  return (
    <>
      <button
        onClick={onClick}
        className={btnClass}>
        {label}{' '}
        {selected ? (
          <FontAwesomeIcon
            className="ms-1"
            icon={faTimesCircle}
          />
        ) : null}
      </button>
    </>
  )
}

export default TagFilterButton
