import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import { getDataObject } from '../../redux/state'
import { modalIsVisible } from '../../redux/ui/state'
import SalesReportGraph from './report/SalesReportGraph'
import SalesReport from './report/SalesReport'

export const ReportModal = props => {
  const { resource, id } = props
  const dispatch = useDispatch()

  const data = useSelector(state => getDataObject(state, resource, id))

  const show = useSelector(state => modalIsVisible(state, `report-${resource}`, id))

  if (!data) return null

  const handleClose = () => {
    dispatch(showModal(`report-${resource}`, id, false))
  }

  return (
    <Modal
      show={show}
      aria-labelledby="login-modal"
      centered
      onHide={handleClose}
      size="xl"
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title id="login-modal">Sales Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SalesReportGraph id={id} />
        <SalesReport id={id} />
      </Modal.Body>
    </Modal>
  )
}

export default ReportModal
