import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { showModal } from '../../redux/ui/actions'
import { getStreamEventPaymentEntity, getStreamEventOfferings, isStreamEventAdmin } from '../../redux/state'
import { useHistory } from 'react-router-dom'

export const TicketList = props => {
  const { id } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const data = useSelector(state => getStreamEventOfferings(state, id))
  const entity = useSelector(state => getStreamEventPaymentEntity(state, id))
  const isAdmin = useSelector(state => isStreamEventAdmin(state, id))

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('tickets-streamevents', id, false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  if (!data) {
    return null
  }

  return (
    <div>
      {data.map((item, index) => {
        return (
          <Purchase
            key={index}
            offering={item}
            className="mb-2"
            isAdmin={isAdmin}
          />
        )
      })}
      <div className="text-end">
        <CheckoutButton
          className="mt-1 btn-block btn-md-inline"
          handleClick={this.checkout}
        />
      </div>
    </div>
  )
}

export default TicketList
