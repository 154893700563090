import React from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import DeleteButton from './DeleteButton'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export const ListItem = props => {
  const { data, className = '', index, handleEdit, handleDelete, handleClone } = props

  const getEditButton = () => {
    if (!handleEdit) {
      return null
    }
    const onClick = e => {
      e.preventDefault()
      e.stopPropagation()
      handleEdit(index)
    }

    let cssClass = 'me-2 cursor-pointer'

    if (!data.active) {
      cssClass += ' text-muted'
    }

    return (
      <button
        className={cssClass}
        title="Edit"
        onClick={onClick}>
        <FontAwesomeIcon icon={faEdit} />
      </button>
    )
  }

  const getCloneButton = () => {
    if (!handleClone) {
      return null
    }
    const onClick = e => {
      e.preventDefault()
      e.stopPropagation()
      handleClone(index)
    }

    let cssClass = 'me-2 cursor-pointer'

    if (!data.active) {
      cssClass += ' text-muted'
    }

    return (
      <button
        className={cssClass}
        title="Clone"
        onClick={onClick}>
        <FontAwesomeIcon icon={faClone} />
      </button>
    )
  }

  let net = Number(data.net)
  const info = ['Net price: £' + net.toFixed(2)]

  let price = net

  if (data.vat) {
    price *= (100 + Number(data.vat)) / 100
    info.push('; VAT: ' + data.vat + '%')
  }

  if (data.admissions) {
    info.push(`; Admits ${data.admissions}`)
  }

  if (data.access && data.access.lifespan && data.access.lifespan.value) {
    info.push(
      '; Lifespan: ' +
        data.access.lifespan.value +
        ' ' +
        data.access.lifespan.unit +
        (data.access.lifespan.value > 1 ? 's' : '')
    )
  }

  if (data.gifting && data.gifting.enabled && data.gifting.reduction) {
    info.push('; Gifting: enabled (' + data.gifting.reduction + '%)')
  }

  if (data.shipping && data.shipping.exempt) {
    info.push('; Free shipping')
  }

  if (typeof data.stock === 'number') {
    info.push(data.stock ? '; Stock: ' + data.stock : '; Out of stock')
  }

  price = price.toFixed(2)

  let cssClass = 'bg-light'
  let badgeVariant = 'primary'

  if (className) {
    cssClass += ' ' + className
  }

  let deleteButton = (
    <DeleteButton
      id={index}
      handleDelete={handleDelete}
    />
  )

  if (!data.active) {
    cssClass += ' text-muted'
    badgeVariant = 'secondary'
    deleteButton = (
      <Badge
        pill
        variant={badgeVariant}>
        Inactive
      </Badge>
    )
  }

  const tooltip = data.terms ? (
    <OverlayTrigger
      key="tooltip-terms"
      placement="top"
      overlay={<Tooltip id={`tooltip-${data.id}`}>{data.terms}</Tooltip>}>
      <a
        className="text-muted ms-1"
        as="button">
        <FontAwesomeIcon icon={faInfoCircle} />
      </a>
    </OverlayTrigger>
  ) : null

  return (
    <Card className={cssClass}>
      <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-start">
        <Badge
          bg={badgeVariant}
          className="me-3">
          £{price}
        </Badge>
        <span className="flex-grow-1">
          <h6 className="mt-0 mb-2">
            {data.name} {tooltip}
          </h6>
          <Form.Text className="text-muted mb-0">{info}</Form.Text>
        </span>
        {getCloneButton()}
        {getEditButton()}
        {deleteButton}
      </Card.Body>
    </Card>
  )
}

export default ListItem
