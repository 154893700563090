export const VideoMimeTypes = [
  {
    ext: 'avi',
    mime: 'video/x-msvideo'
  },
  {
    ext: 'mov',
    mime: 'video/mp4'
  },
  {
    ext: 'mp4',
    mime: 'video/mp4'
  },
  {
    ext: 'mpeg',
    mime: 'video/mpeg'
  },
  {
    ext: 'ogv',
    mime: 'video/ogg'
  },
  {
    ext: 'webm',
    mime: 'video/webm'
  },
  {
    ext: '3gp',
    mime: 'video/3gpp'
  },
  {
    ext: '3g2',
    mime: 'video/3gpp2'
  },
  {
    ext: 'ts',
    mime: 'video/mp2t'
  }
]

export const getVideoMimeType = filename => {
  const ext = filename.split('.').pop()
  return VideoMimeTypes.find(item => item.ext === ext)?.mime
}

export default VideoMimeTypes
