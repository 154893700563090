import './Variables.css'
import './sass/App.scss'
import React from 'react'
import Player from './components/player/Player'
import ChatWindow from './components/chat/ChatWindow'
import FeedWindow from './components/feed/FeedWindow'
import EventFeedWindow from './components/event/FeedWindow'
import EventFoyerWindow from './components/event/FoyerWindow'
import EventScheduleWindow from './components/event/schedule/View'
import EntityFeedWindow from './components/entity/FeedWindow'
import EntityEditWindow from './components/entity/EditWindow'
import EntityTicketHolderWindow from './components/entity/TicketHolders'
import ContentPage from './components/content/dynamic/Page'
import VenueEditWindow from './components/venue/EditWindow'
import ProductFeedWindow from './components/product/FeedWindow'
import ProductWindow from './components/product/ProductWindow'
import UserFeedWindow from './components/user/FeedWindow'
import UserOrderWindow from './components/user/OrderWindow'
import UserTicketWindow from './components/user/TicketWindow'
import SettingsWindow from './components/settings/SettingsWindow'
import CheckoutWindow from './components/cart/PaymentWindow'
import RegistrationWindow from './components/event/RegistrationWindow'
import ContentWindow from './components/content/ContentWindow'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import useApplyDiscountCode from './hooks/use-apply-discount-code'

const Routes = ({ children }) => {
  useApplyDiscountCode()

  return (
    <Switch>
      <Route
        path="/player/:id"
        component={Player}
      />
      <Route
        path="/:resource/:id/chat"
        component={ChatWindow}
      />
      <Route
        path="/feed"
        component={FeedWindow}
      />
      <Route
        path="/events/:id/feed"
        component={EventFeedWindow}
      />
      <Route
        path="/events/:id/foyer"
        component={EventFoyerWindow}
      />
      <Route
        path="/events/:id/schedule"
        component={EventScheduleWindow}
      />
      <Route
        path="/entities/:id/feed"
        component={EntityFeedWindow}
      />
      <Route
        path="/entities/:entity/content/:id"
        component={ContentPage}
      />
      <Route
        path="/entities/:id/edit"
        component={EntityEditWindow}
      />
      <Route
        path="/entities/:id/products/feed"
        component={ProductFeedWindow}
      />
      <Route
        path="/entities/:id/ticket-holders/"
        component={EntityTicketHolderWindow}
      />
      <Route
        path="/products/:id"
        component={ProductWindow}
      />
      <Route
        path="/entities/:id/checkout"
        component={CheckoutWindow}
      />
      <Route
        path="/venues/:id/edit"
        component={VenueEditWindow}
      />
      <Route
        path="/users/:id/feed"
        component={UserFeedWindow}
      />
      <Route
        path="/users/:id/orders"
        component={UserOrderWindow}
      />
      <Route
        path="/users/:id/tickets"
        component={UserTicketWindow}
      />
      <Route
        path="/:resource/:id/feed"
        component={FeedWindow}
      />
      <Route
        path="/users/:id/settings/entities/:entity/:group"
        component={SettingsWindow}
      />
      <Route
        path="/users/:id/settings/:group"
        component={SettingsWindow}
      />
      <Route
        path="/users/:id/settings"
        component={SettingsWindow}
      />
      <Route
        path="/events/:id/register"
        component={RegistrationWindow}
      />
      <Route path="/privacy-policy">
        <ContentWindow component="PrivacyPolicy" />
      </Route>
      <Route>
        <Home />
      </Route>
    </Switch>
  )
}

export default Routes
