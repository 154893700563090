import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import TimeString from '../display/TimeString'
import HourString from '../display/HourString'
import DayString from '../display/DayString'
import FeedItem from './FeedItem'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFeed } from '../../redux/feed/actions'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { setFeedDisplay } from '../../redux/ui/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { getCurrentUser, isEventAdmin } from '../../redux/state'

export const Feed = props => {
  const { name, split, resource, grouping, cols = 1 } = props

  const dispatch = useDispatch()

  const status = useSelector(state => {
    const { feeds } = state
    return feeds[name]?.status || 'initialised'
  })

  const filter = useSelector(state => {
    const { feeds } = state
    return feeds[name]?.filter || props.filter
  })

  const sort = useSelector(state => {
    const { feeds } = state
    const sort = feeds[name]?.sort
    if (Array.isArray(sort) && sort.length >= 1 && typeof sort[0] === 'object' && !Object.keys(sort[0]).length)
      return null

    if (typeof sort === 'object' && !Object.keys(sort).length) return null
    return sort && !Array.isArray(sort) ? [sort] : sort
  })

  const scrollPosition = useSelector(state => {
    const { feeds } = state
    return feeds[name]?.scrollPosition || null
  })

  const user = useSelector(state => getCurrentUser(state))

  const isSplit = useSelector(state => {
    const { ui } = state
    const { [name]: feed } = ui?.feeds || {}
    if (!(sort || []).includes('datetime')) return false
    if (feed?.display === 'all') return false
    const urlParams = new URLSearchParams(window.location.search)
    if (split) return true
    if (urlParams.get('show') === 'all') return false
    return true
  })

  const { data, hasSplit } = useSelector(state => {
    const { feeds } = state
    if (!feeds[name] || feeds[name]?.status !== 'fetched') return []
    const { items } = feeds[name]

    const now = new Date()
    const tags = Array.isArray(filter?.tags) ? filter?.tags : []

    const { future, past } = (items || [])
      .filter(
        item =>
          !tags.length ||
          (state[item.resource][item.id].tags &&
            state[item.resource][item.id].tags.filter(tag => tags.includes(tag)).length)
      )
      .map(item => state[item.resource][item.id])
      .filter(item => item.visibility === 'public' || isEventAdmin(state, item.id))
      .reduce(
        (agg, item) => {
          if (
            (sort || ['datetime']).includes('datetime') &&
            item &&
            new Date(item.datetime).getTime() + item.duration * 60000 >= now.getTime()
          ) {
            agg.future.push(item)
          } else if (item) {
            agg.past.push(item)
          }
          return agg
        },
        { future: [], past: [] }
      )

    const hasSplit = future.length && past.length
    let data = hasSplit && isSplit ? future : past.concat(future)

    if (data.length && (sort || []).includes('datetime')) {
      const populate = item => {
        let children = Object.keys(state[item.__type])
          .filter(key => key === state[item.__type][key].id && state[item.__type][key].parent === item.id)
          .map(key => state[item.__type][key])
        if (children.length) {
          return children.concat(children.map(child => populate(child))).flat()
        }
        return []
      }
      data = data.concat(data.map(item => populate(item)).flat()).filter(item => item.datetime)

      data = data.filter((item, index) => index === data.findIndex(i => i.id === item.id && i.__type === item.__type))
    }

    return {
      hasSplit,
      data: data.sort((a, b) => {
        switch (true) {
          case (sort || []).includes('-rating'):
            const { rating: aRating = 0 } = a,
              { rating: bRating = 0 } = b
            return Number(bRating) - Number(aRating)
          case (sort || []).includes('title'):
            return (a.title || a.name).localeCompare(b.title || b.name)
          case (sort || []).includes('-created'):
            return new Date(a.created).getTime() <= new Date(b.created).getTime() ? 1 : -1
          default:
            return new Date(a.datetime).getTime() <= new Date(b.datetime).getTime() ? -1 : 1
        }
      })
    }
  })

  useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => {
        if ($(`#${scrollPosition}`).length) {
          $('html, body').animate(
            {
              scrollTop: $(`#${scrollPosition}`).offset().top - $('.feed-header__controls').height() - 15 + 'px'
            },
            {
              duration: 100,
              complete: () => {
                if ($(`#${scrollPosition}`).length) {
                  dispatch(setFeedScrollPosition(name, null))
                }
              }
            }
          )
        }
      }, 250)
    } else {
      $('html, body').animate(
        {
          scrollTop: $('#root').offset().top + 'px'
        },
        {
          duration: 0
        }
      )
    }
  }, [name])

  useEffect(() => {
    dispatch(fetchFeed(name, resource, props.filter, sort, { clear: true }))
  }, [name])

  useEffect(() => {
    dispatch(fetchFeed(name, resource, filter || props.filter, sort, { clear: true }, true))
  }, [user])

  const togglePastEvents = () => {
    const display = isSplit ? 'all' : 'future'
    dispatch(setFeedDisplay(name, display))
  }

  const getGroupHeading = (newDate, storedDate = null, grouping = 'minute', key) => {
    if (!newDate) {
      return null
    }
    let rounding = 1000 * 60
    if (grouping === 'hour') {
      rounding *= 60
    } else if (grouping === 'day') {
      rounding *= 1440
    }
    newDate = new Date(Math.floor(new Date(newDate) / rounding) * rounding)
    storedDate = storedDate ? new Date(Math.floor(new Date(storedDate) / rounding) * rounding) : null
    if (!storedDate || newDate.getTime() !== storedDate.getTime()) {
      switch (grouping) {
        case 'day':
          return (
            <Col
              key={key}
              xs={12}
              className="px-2">
              <h2 className="mt-4 mb-1 pb-1 feed__heading feed__heading-day text-center border-bottom">
                <DayString time={newDate} />
              </h2>
            </Col>
          )
        case 'hour':
          return (
            <Col
              key={key}
              xs={12}
              className="px-2">
              <h3 className="mt-2 mb-1 feed__heading feed__heading-hour">
                <HourString time={newDate} />
              </h3>
            </Col>
          )
        default:
          return (
            <Col
              key={key}
              xs={12}
              className="px-2">
              <h3 className="mt-2 mb-1 feed__heading feed__heading-minute">
                <TimeString time={newDate} />
              </h3>
            </Col>
          )
      }
    }
    return null
  }

  if (!data || !data.length) {
    if (status === 'fetching') {
      return (
        <Container>
          <Row>
            <Col className="text-center py-5">
              <Spinner
                animation="border"
                role="status"
                variant="secondary"
              />
            </Col>
          </Row>
        </Container>
      )
    }
    return null
  }

  const showAll = hasSplit ? (
    <Row>
      <Col className="px-0 mb-1">
        <button
          onClick={togglePastEvents}
          className={isSplit ? 'text-dark' : 'text-muted'}>
          <FontAwesomeIcon icon={faHistory} /> {isSplit ? 'Show past events' : 'Hide past events'}
        </button>
      </Col>
    </Row>
  ) : null

  const sortMatch = (Array.isArray(sort) && sort.length ? sort[0] : sort || '').match(/^-?(datetime|created)$/)
  const groupField = sortMatch ? sortMatch[1] : null
  const grouped = grouping && groupField
  let time

  return (
    <Container className="feed">
      {showAll}
      <Row className="feed__row align-items-start">
        {data.map((item, index) => {
          let heading, dayHeading
          if (grouped) {
            heading = getGroupHeading(item[groupField], time, grouping, 'heading')
            if (grouping !== 'day') {
              dayHeading = getGroupHeading(item[groupField], time, 'day', 'day')
            }
            time = item[groupField]
          }
          return (
            <React.Fragment key={index}>
              {dayHeading}
              {heading}
              <Col
                key={item.id}
                className="px-2 pb-3 feed__grid"
                xs={12}
                md={12 / cols}>
                <FeedItem
                  item={item}
                  name={name}
                />
              </Col>
            </React.Fragment>
          )
        })}
      </Row>
    </Container>
  )
}

export default Feed
