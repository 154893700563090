import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'

export const ScheduleButton = props => {
  const history = useHistory()

  const { id, className = '' } = props

  const handleClick = e => {
    history.push(`/events/${id}/schedule`)
  }

  return (
    <Button
      title="View planner"
      variant="light"
      onClick={handleClick}
      className={className}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <span className="d-none d-md-inline ms-2">Planner</span>
    </Button>
  )
}

export default ScheduleButton
