import { Badge, ListGroup } from 'react-bootstrap'
import ActivationButton from '../access/ActivationButton'
import Title from '../access/Title'

export default props => {
  const { access } = props

  let badgeBg = 'secondary'

  const status = `${access.status.substring(0, 1).toUpperCase()}${access.status.substring(1)}`

  switch (access.status) {
    case 'unused':
      badgeBg = 'success'
      break
    case 'activated':
      badgeBg = 'warning'
      break
    default:
      badgeBg = 'secondary'
  }

  return (
    <ListGroup.Item
      disabled={access.status !== 'unused'}
      className="d-flex justify-content-between align-items-start">
      <div>
        <p className="m-0">{access.owner?.name}</p>
        <p className="text-small m-0">{access.offering?.name}</p>
      </div>
      <span>
        <Badge
          bg={badgeBg}
          pill
          className="ms-2">
          {status}
        </Badge>
        <ActivationButton
          data={access}
          size="sm"
          className="ms-2"
        />
      </span>
    </ListGroup.Item>
  )
}
