import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDataObject, getPaymentEntity } from '../../../redux/state'
import TimeLine from './TimeLine'
import { fetchEvent } from '../../../redux/event/actions'
import { getEvent } from '../../../redux/state'
import { fetchFeed } from '../../../redux/feed/actions'
import { useHistory, useParams } from 'react-router-dom'
import VenueRow from './VenueRow'
import ScheduleCss from './Css'
import ScheduleKey from './Key'
import Spinner from 'react-bootstrap/Spinner'

import './Schedule.scss'
import { getVenue } from '../../../redux/venue/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Cart from './Cart'
import { setCurrentTenant } from '../../../redux/ui/actions'
import TicketButton from '../TicketButton'

export const ScheduleView = props => {
  const { id = props.id } = useParams()

  const dispatch = useDispatch()
  const history = useHistory()

  const name = `events-${id}-schedule`

  useEffect(() => {
    dispatch(fetchEvent(id, true))
  }, [id])

  const event = useSelector(state => getEvent(state, id))

  const { start, end } = useSelector(state => {
    const event = getEvent(state, id)
    if (event && event.datetime && event.duration) {
      const duration = Math.ceil((event.duration || 15) / 15) * 15
      const start = new Date(event.datetime)
      start.setMinutes(Math.floor(start.getMinutes() / 15) * 15)
      start.setSeconds(0)
      const end = new Date(start.getTime() + duration * 60000)
      return { start, end }
    }
    return { start: null, end: null }
  })

  const paymentEntity = useSelector(state =>
    event && event.entity && event.entity.id ? getPaymentEntity(state, event.entity.id) : null
  )

  useEffect(() => {
    if (event && event.id) {
      const filter = { event: event.id }
      dispatch(fetchFeed(name, ['events', 'streamevents'], filter, {}, { clear: true }))
      dispatch(setCurrentTenant('events', event.id))
    }
  }, [event])

  let venues = [],
    tags = []

  const periods = useSelector(state => {
    venues = []
    let tagSet = {}

    const { feeds } = state
    if (!feeds) return []

    const { [name]: feed } = feeds
    if (!feed || !feed.items || !feed.items.length) return []

    let periodIndex = 0
    let periodStart, periodEnd

    const periods = feed.items
      .reduce((agg, item) => {
        const { resource, id } = item
        const eventObject = getDataObject(state, resource, id)
        if (!eventObject || !eventObject.datetime) return agg

        const { datetime, duration, tags } = eventObject

        eventObject.duration = Math.ceil((duration || 15) / 15) * 15
        eventObject.start = new Date(datetime)
        eventObject.start.setMinutes(Math.round(eventObject.start.getMinutes() / 15) * 15)
        eventObject.start.setSeconds(0)
        eventObject.end = new Date(eventObject.start.getTime() + eventObject.duration * 60000)

        if (tags && tags.length) tagSet[tags[0]] = tags[0]

        if (eventObject.end < start || eventObject.start > end) return agg

        agg.push(eventObject)
        return agg
      }, [])
      .sort((a, b) => {
        return a.start < b.start ? -1 : 1
      })
      .reduce((agg, eventObject, itemIndex, arr) => {
        if (!periodStart) periodStart = new Date(eventObject.start.getTime())
        if (!agg[periodIndex]) agg[periodIndex] = { start: periodStart, venues: {} }

        if (periodEnd && eventObject.start > periodEnd) {
          agg[periodIndex].end = periodEnd
          periodIndex++
          periodStart = new Date(eventObject.start.getTime())
          agg[periodIndex] = { start: periodStart, venues: {} }
          periodEnd = null
        }

        if (!periodEnd || eventObject.end > periodEnd) periodEnd = new Date(eventObject.end.getTime())

        let key = 'undefined'
        if (eventObject.__type === 'streamevents') key = '__livestream'
        const { venue } = eventObject
        if (venue && venue.id) key = venue.id

        if (!venues.includes(key)) venues.push(key)

        if (!agg[periodIndex].venues[key]) {
          agg[periodIndex].venues[key] = []
        }
        agg[periodIndex].venues[key].push(eventObject)

        if (itemIndex === arr.length - 1) agg[periodIndex].end = periodEnd

        return agg
      }, [])

    if (periods.length) {
      periods[periods.length - 1].end = periodEnd
    }

    venues.sort((a, b) => {
      const venueA = getVenue(state, a)
      const venueB = getVenue(state, b)
      if (venueA && venueB) return venueA.position - venueB.position
      if (venueA) return -1
      if (venueB) return 1
      return a.localeCompare(b)
    })

    tags = Object.keys(tagSet)

    return periods
  })

  if (!periods || !periods.length || !start || !end)
    return (
      <div className="text-center py-5 text-white">
        <Spinner
          animation="border"
          role="status"
          variant="light"
        />
        <div>Loading</div>
      </div>
    )

  const venueRows = venues
    .filter(venue => venue && venue !== 'undefined')
    .map(venue => ({
      periods: periods.map(({ start, end, venues }) => ({ name: venue, start, end, data: venues[venue] || [] })),
      name: venue
    }))
    .sort((a, b) => {
      const indexA = venues.indexOf(a.name)
      const indexB = venues.indexOf(b.name)
      if (!(indexA < 0 && indexB < 0)) {
        return indexA - indexB
      }
      return a.name.localeCompare(b.name)
    })

  const columnTotal = periods.reduce((agg, period) => {
    agg++
    agg += Math.round((period.end.getTime() - period.start.getTime()) / 900000)
    return agg
  }, 0)

  const onEventClick = () => {
    if (event) history.push('/events/' + (event.alias || event.id) + '/feed')
  }

  return (
    <>
      <ScheduleCss tags={tags} />
      <table
        className="bg-dark text-white border-secondary border-top-gray-700 position-relative"
        style={{ height: '1px' }}>
        <thead>
          <tr>
            <th
              className="border-bottom-gray-700 schedule_table_title"
              colSpan={columnTotal}>
              <div className="d-flex justify-content-start align-items-center">
                <h1 className="fs-2 m-0 p-2 fw-normal bg-dark">
                  <span
                    className="cursor-pointer"
                    onClick={onEventClick}
                    title="Programme">
                    <span className="fs-3 text-gray-600 me-2">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    {event.name}
                  </span>{' '}
                  <span className="text-gray-600">Planner</span>
                </h1>
                <ScheduleKey
                  tags={tags}
                  className="ms-4"
                />
              </div>
            </th>
          </tr>
        </thead>
        <thead>
          <TimeLine periods={periods} />
        </thead>
        {venueRows.map(venue => (
          <VenueRow
            key={venue.name}
            {...venue}
          />
        ))}
      </table>
      <div className="position-fixed end-0 bottom-0">
        <TicketButton
          variant="secondary"
          id={event.id}
          size="sm"
          className="me-2 mb-2"
        />
        <Cart entity={paymentEntity} />
      </div>
    </>
  )
}

export default ScheduleView
