import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import {
  faSitemap,
  faImage,
  faThList,
  faUnlockAlt,
  faMousePointer,
  faCode,
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons'
import ImageSelector from '../form/ImageSelector'
import ImagesSelector from '../form/ImagesSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import Section from '../form/Section'
import Accordion from 'react-bootstrap/Accordion'
import EntitySelector from '../entity/EntitySelector'
import { adminEntitiesAreLoaded, adminContentIsLoaded } from '../../redux/user/state'
import Spinner from 'react-bootstrap/Spinner'
import useFormGetSet from '../../hooks/use-form-get-set'
import { aliasExists, contentIsLoaded, getChildContent, getContent, isContentOwner } from '../../redux/content/state'
import {
  patchContent,
  postContent,
  fetchContent,
  fetchAdministratorContent,
  fetchChildContent
} from '../../redux/content/actions'
import { useState } from 'react'
import { useEffect } from 'react'
import DeleteButton from './DeleteButton'
import Selector from './Selector'
import EventSelector from '../event/EventSelector'
import { isEntityAdmin } from '../../redux/state'
import ChildSorter from './ChildSorter'
import { useHistory } from 'react-router'
import EventTypeSelector from '../event/EventTypeSelector'
import EventOfferingCategorySelector from '../event/EventOfferingCategorySelector'
import VideoSelector from '../form/VideoSelector'

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    id = 'new',
    parent = { type: 'content' },
    entity,
    onValidate,
    onSave,
    onError,
    handleClose,
    modalBody
  } = props

  const data = useSelector(state => getContent(state, id))

  const entities = useSelector(state => {
    const { user } = state
    if (user && user.entities && Array.isArray(user.entities)) {
      return user.entities.reduce((agg, id) => {
        agg[id] = { ...state.entities[id], ...{ id } }
        return agg
      }, {})
    }
    return null
  })

  const entityAdmin = useSelector(state => isEntityAdmin(state, entity))
  const adminElementsLoaded = useSelector(state => adminEntitiesAreLoaded(state))
  const dataLoaded = useSelector(state => id === 'new' || contentIsLoaded(state, id))
  const children = useSelector(state => (id !== 'new' ? getChildContent(state, 'content', id) : null))

  const [validated, setValidated] = useState(false)
  const [aliasFeedback, setAliasFeedback] = useState(`Please enter the content's alias.`)
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState()
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [currentID, setCurrentID] = useState(id)

  const [stateData, setStateData, getValue, setValue, setValues] = useFormGetSet(
    data || {
      title: '',
      alias: '',
      parent,
      entity: entity ? { id: entity } : {},
      image: '',
      images: [],
      content: '',
      format: 'text',
      visibility: 'private',
      active: false,
      navigation: {
        show: false
      },
      feed: {},
      tags: []
    },
    () => setUnsavedChanges(true)
  )

  useEffect(() => {
    if (currentID !== 'new') {
      dispatch(fetchContent(entity, currentID, true)).then(result => {
        if (result) {
          const data = {
            ...result.data.attributes,
            ...{ id: result.data.id },
            ...{ entity: result.data.relationships.entity.data }
          }
          setStateData(data)
          setUnsavedChanges(false)
        }
      })
    }
  }, [entity, currentID])

  useEffect(() => {
    dispatch(fetchAdministratorContent(entity, true))
  }, [entity, entityAdmin])

  useEffect(() => {
    dispatch(fetchChildContent('content', id))
  }, [id])

  const handleInputChange = event => {
    let { name, value, type, checked } = event.target
    if (type === 'checkbox') {
      value = Boolean(checked)
    }
    if (name === 'alias') {
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    if (name === 'entity' && value && entities && entities[value]) {
      value = { id: value, name: entities[value].name, alias: entities[value].alias }
    }
    let updated
    if (name === 'parent_type') {
      const keyPairs = { [name]: value, parent_id: '' }
      updated = setValues(keyPairs)
    } else if (name === 'title' && currentID === 'new') {
      const keyPairs = { [name]: value }
      if (currentID === 'new') {
        keyPairs.alias = value.replace(/\W+/g, '-').toLowerCase()
      }
      updated = setValues(keyPairs)
    } else {
      updated = setValue(name, value)
    }
    setUnsavedChanges(true)
    if ((name === 'title' && currentID === 'new') || name === 'alias') {
      validateAlias(updated.alias).then(validated => {
        setValidated(!validated)
        setAliasFeedback(validated ? `Please enter the content's alias.` : 'This alias is already in use.')
        $('#ContentForm input[name="alias"]')
          .get(0)
          .setCustomValidity(validated ? '' : 'Invalid field')
      })
    }
  }

  const handleTagChange = (tags, name = 'tags') => {
    tags = tags.map(tag => tag.text)
    setValue(name, tags)
  }

  const validateAlias = async alias => {
    if (data && data.alias && data.alias === alias) {
      return Promise.resolve(true)
    }
    const result = await aliasExists(entity, alias)
    return !result
  }

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    saveData(event.currentTarget, event.nativeEvent.submitter.value)
  }

  const saveData = (form, action) => {
    var passed = form.checkValidity()
    setValidated(true)
    if (onValidate) {
      onValidate(form)
    }
    if (!passed) {
      let invalid = form.querySelectorAll(':invalid')
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const { ...updated } = stateData
    if (entities && Object.keys(entities).length && (!updated.entity || !updated.entity.id)) {
      const entityID = entity || Object.keys(entities)[0]
      let { alias, name } = entities[entityID]
      updated.entity = {
        id: entityID,
        alias,
        name
      }
    }
    updated.active = 1
    // Submit post
    Promise.resolve()
      .then(() => {
        if (currentID !== 'new') {
          return dispatch(patchContent(updated))
        }
        delete updated.id
        delete updated.action
        return dispatch(postContent(entity, updated))
      })
      .then(result => {
        if (result && result.data && result.data.id && currentID === 'new') {
          setValue('id', result.data.id)
          setCurrentID(result.data.id)
        }
        if (result.error) return handleSaveError(result.error)
        if (/\bclose\b/.test(action)) {
          const { pathname, search } = history.location
          history.push(`${pathname}${search}`)

          return dispatch(showModal('content', id, false))
        } else {
          handleSaveSuccess(result)
        }
      })
      .catch(err => {
        handleSaveError(err)
      })
  }

  const handleSaveSuccess = result => {
    setValidated(false)
    setUnsavedChanges(false)
    setFeedbackMessage('Content saved successfully')
    if (onSave) {
      onSave(result)
    }
  }

  const handleSaveError = err => {
    setValidated(false)
    setFeedbackMessage(err.message)
    console.log(err.message)
    if (onError) {
      onError(err)
    }
  }

  if (!adminElementsLoaded || !dataLoaded) {
    return (
      <div className="text-center py-5">
        <Spinner
          animation="border"
          role="status"
          variant="secondary"
        />
      </div>
    )
  }

  const headerClass = 'text-secondary'

  let tags = data && data.tags ? data.tags.map(tag => ({ id: tag, text: tag })) : []

  return (
    <Form
      novalidate="true"
      validated={validated}
      onSubmit={handleSubmit}
      id="ContentForm"
      className="bg-white py-1 px-2 pb-3">
      <Accordion defaultActiveKey="0">
        <Section
          title="Content Settings"
          eventKey="0"
          className={headerClass}
          icon={faImage}>
          <Form.Group controlId="ContentNameInput">
            <Form.Label className="mt-2 mb-0">Name</Form.Label>
            <Form.Control
              name="title"
              onChange={handleInputChange}
              placeholder="The content's title"
              value={getValue('title')}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter the content's title</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="ContentAliasInput">
            <Form.Label className="mt-2 mb-0">Alias</Form.Label>
            <Form.Control
              name="alias"
              onChange={handleInputChange}
              placeholder="The content's alias"
              value={getValue('alias')}
              required
            />
            <Form.Control.Feedback type="invalid">{aliasFeedback}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="ContentTextInput">
            <Form.Label className="mt-2 mb-0">Copy</Form.Label>
            <Form.Control
              name="content"
              as="textarea"
              rows="5"
              onChange={handleInputChange}
              placeholder="The content text"
              value={getValue('content')}
            />
          </Form.Group>

          <Form.Group controlId="ContentFormatInput">
            <Form.Label className="mt-2 mb-0">Text format</Form.Label>
            <Form.Control
              as="select"
              name="format"
              onChange={handleInputChange}
              value={getValue('format')}>
              <option value="text">Plain text</option>
              <option value="markdown">Markdown</option>
              <option value="html">HTML</option>
            </Form.Control>
            <Form.Text className="text-muted">
              <a href="https://www.markdownguide.org/basic-syntax/">Markdown</a> lets you format content without having
              to learn HTML.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="ContentImageInput">
            <Form.Label className="mt-2 mb-0">Main image</Form.Label>
            <div
              className="input-group align-items-start"
              id="ContentImageInput"
              data-target-input="nearest">
              <ImageSelector
                key={`image-${unsavedChanges}`}
                height={2560}
                image={getValue('image')}
                name="image"
                type="image"
                handleChange={setValue}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="ContentImagesInput">
            <Form.Label className="mt-2 mb-0">Additional images</Form.Label>
            <div
              id="ContentImagesInput"
              data-target-input="nearest">
              <ImagesSelector
                key={`images-${unsavedChanges}`}
                images={getValue('images')}
                name="images"
                handleChange={setValue}
                container={modalBody}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="ContentPreviewInput">
            <Form.Label className="mt-2 mb-0">Preview</Form.Label>
            <Form.Control
              name="preview_text"
              onChange={handleInputChange}
              placeholder="The content's preview text"
              value={getValue('preview.text')}
            />
            <Form.Control.Feedback type="invalid">{aliasFeedback}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="ContentVideoInput">
            <Form.Label className="mt-2 mb-0">Video</Form.Label>
            <div
              className="input-group align-items-start"
              id="ContentVideoInput"
              data-target-input="nearest">
              <VideoSelector
                key={`video-${unsavedChanges}`}
                image={getValue('video')}
                name="video"
                handleChange={setValue}
              />
            </div>
          </Form.Group>
        </Section>

        <Section
          title="Site Settings"
          eventKey="1"
          className={headerClass}
          icon={faSitemap}>
          <EntitySelector
            id="ContentOrganisationInput"
            name="entity_id"
            onChange={handleInputChange}
            value={getValue('entity.id')}
            label="Organisation"
          />

          <Form.Group controlId="ContentParentTypeInput">
            <Form.Label className="mt-2 mb-0">Parent type</Form.Label>
            <Form.Control
              as="select"
              name="parent_type"
              onChange={handleInputChange}
              value={getValue('parent.type')}>
              <option value="content">Content</option>
              <option value="event">Event</option>
            </Form.Control>
          </Form.Group>

          {getValue('parent.type') === 'event' ? (
            <EventSelector
              id="ContentParentEventInput"
              name="parent_id"
              onChange={handleInputChange}
              value={getValue('parent.id')}
              label="Parent event"
              allowNull={true}
            />
          ) : (
            <Selector
              id="ContentParentContentInput"
              entity={entity}
              name="parent_id"
              onChange={handleInputChange}
              value={getValue('parent.id')}
              label="Parent page"
              allowNull={true}
            />
          )}
        </Section>

        <Section
          title="Navigation Settings"
          eventKey="2"
          className={headerClass}
          icon={faMousePointer}>
          <Form.Group controlId="ContentNavigationShowInput">
            <Form.Check
              type="checkbox"
              label="Show in menu"
              name="navigation_show"
              value="1"
              onChange={handleInputChange}
              checked={getValue('navigation.show')}
            />
          </Form.Group>
          <Form.Group controlId="ContentNavigationTitleInput">
            <Form.Label className="mt-2 mb-0">Title</Form.Label>
            <Form.Control
              name="navigation_title"
              onChange={handleInputChange}
              placeholder="A title for the content in menus"
              value={getValue('navigation.title')}
            />
          </Form.Group>
          <Form.Group controlId="ContentNavigationTitleInput">
            <Form.Label className="mt-2 mb-0">URL</Form.Label>
            <Form.Control
              name="navigation_url"
              onChange={handleInputChange}
              placeholder="An external URL for to direct the user to when menu items are clicked"
              value={getValue('navigation.url')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Metadata Settings"
          eventKey="2"
          className={headerClass}
          icon={faCode}>
          <Form.Group controlId="ContentMetaTitleInput">
            <Form.Label className="mt-2 mb-0">Title</Form.Label>
            <Form.Control
              name="meta_title"
              onChange={handleInputChange}
              placeholder="Meta title"
              value={getValue('meta.title')}
            />
          </Form.Group>
          <Form.Group controlId="ContentMetaDescriptionInput">
            <Form.Label className="mt-2 mb-0">Description</Form.Label>
            <Form.Control
              name="meta_description"
              as="textarea"
              onChange={handleInputChange}
              placeholder="Meta description"
              value={getValue('meta.description')}
            />
          </Form.Group>
          <Form.Group controlId="ContentMetaImageInput">
            <Form.Label className="mt-2 mb-0">Image</Form.Label>
            <div
              className="input-group align-items-start"
              id="MetaImageInput"
              data-target-input="nearest">
              <ImageSelector
                key={`meta-image-${unsavedChanges}`}
                height={2560}
                image={getValue('meta.image')}
                name="meta_image"
                type="image"
                handleChange={setValue}
              />
            </div>
          </Form.Group>
        </Section>

        <Section
          title="Access Settings"
          eventKey="4"
          className={headerClass}
          icon={faUnlockAlt}>
          <Form.Group controlId="ContentVisiblityInput">
            <Form.Label className="mt-2 mb-0">Visibility</Form.Label>
            <Form.Control
              as="select"
              name="visibility"
              onChange={handleInputChange}
              value={getValue('visibility')}>
              <option value="private">Private</option>
              <option value="public">Public</option>
            </Form.Control>
          </Form.Group>
        </Section>

        <Section
          title="Display Settings"
          eventKey="5"
          className={headerClass}
          icon={faThList}>
          <Form.Group controlId="ContentDisplayThemeInput">
            <Form.Label className="mt-2 mb-0">Theme</Form.Label>
            <Form.Control
              as="select"
              name="display_theme"
              onChange={handleInputChange}
              value={getValue('display.theme')}>
              <option value="panorama">Panorama</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="ContentDisplayContentInput">
            <Form.Label className="mt-2 mb-0">Content</Form.Label>
            <CheckboxGroup
              options={[
                { value: 'content', label: 'Sub-pages' },
                { value: 'events', label: 'Events' }
              ]}
              name="display_content"
              value={getValue('display.content') || ['content']}
              onChange={setValue}
            />
          </Form.Group>

          <Form.Group controlId="ContentDisplaySortInput">
            <Form.Label className="mt-2 mb-0">Display order</Form.Label>
            <Form.Control
              as="select"
              name="display_sort"
              onChange={handleInputChange}
              value={getValue('display.sort')}>
              <option value="datetime">Event times</option>
              <option value="-created">Latest post</option>
              <option value="title">Alphabetical</option>
              <option value="-rating">Rating</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="ContentTagsInput">
            <Form.Label className="mt-2 mb-0">Tags</Form.Label>
            <TagSelector
              tags={tags}
              handleChange={handleTagChange}
            />
          </Form.Group>

          <Form.Group controlId="ContentCSSInput">
            <Form.Label className="mt-2 mb-0">CSS</Form.Label>
            <Form.Control
              name="display_css"
              as="textarea"
              rows="4"
              onChange={handleInputChange}
              placeholder="Custom CSS for the page"
              value={getValue('display.css')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Ticket Settings"
          eventKey="6"
          className={headerClass}
          icon={faTicketAlt}>
          <EventTypeSelector
            label="Featured Event"
            id="ContentTicketEventInput"
            name="featuredEvent"
            onChange={v => (v.length ? setValue('featuredEvent', v[0].id) : setValue('featuredEvent', null))}
            value={getValue('featuredEvent')}
          />
          <EventOfferingCategorySelector
            event={data?.featuredEvent}
            label="Ticket Category"
            id="ContentTicketCategoryInput"
            name="ticketCategory"
            value={getValue('ticketCategory')}
            onChange={handleInputChange}
          />
        </Section>

        {!children || !children.length ? null : (
          <Section
            title="Sub-Pages"
            eventKey="7"
            className={headerClass}
            icon={faThList}>
            <ChildSorter
              children={children}
              container={modalBody}
            />
          </Section>
        )}
      </Accordion>

      <Form.Group className={feedbackMessage ? 'd-block' : 'd-none'}>
        <Form.Control.Feedback type="valid-feedback">{feedbackMessage}</Form.Control.Feedback>
      </Form.Group>

      <div className="mt-3">
        <DeleteButton
          entity={entity}
          id={currentID}
          handleDelete={() => dispatch(showModal('content', currentID, false))}
        />
        <Button
          name="action"
          value="save-close"
          variant="primary"
          type="submit"
          className="ms-2">
          Save &amp; Close
        </Button>
      </div>
    </Form>
  )
}
