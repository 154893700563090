import { useDispatch } from 'react-redux'
import { patchContent } from '../../redux/content/actions'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from '../../common/array-move'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState } from 'react'

const SortableItem = SortableElement(({ child }) => {
  return <ListGroup.Item className="sortableItem cursor-grab">{child.title}</ListGroup.Item>
})

const SortableList = SortableContainer(({ children }) => {
  return (
    <ListGroup className="sortableContainer">
      {children.map((child, index) => (
        <SortableItem
          key={`item-${child.idl}-${index}`}
          child={child}
          index={index}
        />
      ))}
    </ListGroup>
  )
})

export default props => {
  const dispatch = useDispatch()
  const { children, container } = props
  const [data, setData] = useState(children)

  if (!data) return null

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [...updated] = data
    arrayMove(updated, oldIndex, newIndex)
    setData(updated)
    updated.forEach((child, ordinal) => {
      const { id, parent, entity } = child
      const update = {
        id,
        parent: { ...parent, ...{ ordinal } },
        entity
      }
      dispatch(patchContent(update))
    })
  }

  return (
    <SortableList
      children={data}
      onSortEnd={onSortEnd}
      helperClass="sortableHelper"
      axis="y"
      helperContainer={container ? container.current : null}
      distance={1}
    />
  )
}
