import { RECEIVE_RESOURCE, RECEIVE_RESOURCES, RECEIVE_RESOURCE_DELETION } from '../crud/actions'

import {
  REQUEST_EVENT_REGISTRATION,
  RECEIVE_EVENT_REGISTRATION,
  CLEAR_EVENT_REGISTRATION,
  RECEIVE_EVENT_SALES_REPORT,
  RECEIVE_EVENT_SALES_SUMMARY,
  RECEIVE_TICKETED_EVENTS,
  RECEIVE_EVENT_ACCESSES
} from './actions'

import { RECEIVE_COMMENTS, RECEIVE_COMMENT_COUNT } from '../comment/actions'

let key

export const events = (state = {}, action) => {
  // Event specific actions
  switch (action.type) {
    case RECEIVE_EVENT_SALES_REPORT:
      key = action.includeSubEvents ? '__allSales' : '__sales'
      return {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...{ [key]: action.data.map(item => ({ ...item.attributes })) }
          }
        }
      }
    case RECEIVE_EVENT_SALES_SUMMARY:
      key = action.includeSubEvents ? '__allSalesSummary' : '__salesSummary'
      return {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...{ [key]: { ...action.data.attributes } }
          }
        }
      }

    case RECEIVE_TICKETED_EVENTS:
      key = '__ticketed'
      return {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...{ [key]: action.data.map(item => item.id) }
          }
        }
      }
    case RECEIVE_EVENT_ACCESSES:
      key = '__accesses'
      return {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...{
              [key]: action.data.reduce(
                (agg, access) => {
                  const accessPayload = { ...access.attributes, ...{ id: access.id } }
                  const accessIndex = agg.findIndex(access => access.id === accessPayload.id)
                  if (accessIndex < 0) {
                    agg.push(accessPayload)
                  } else {
                    agg[accessIndex] = accessPayload
                  }
                  return agg
                },
                [...(state[action.id].__accesses || [])]
              )
            }
          }
        }
      }
    case RECEIVE_RESOURCE:
      if (action?.resource === 'accesses' && action?.data?.attributes?.resource?.type === 'events') {
        const eventId = action?.data?.attributes?.resource?.id
        const eventAccesses = [...(state[eventId].__accesses || [])]
        const accessPayload = { ...action.data.attributes, ...{ id: action.id } }
        const accessIndex = eventAccesses.findIndex(access => access.id === accessPayload.id)
        if (accessIndex < 0) {
          eventAccesses.push(accessPayload)
        } else {
          eventAccesses[accessIndex] = accessPayload
        }
        return {
          ...state,
          ...{
            [eventId]: {
              ...(state[eventId] || {}),
              ...{
                __accesses: eventAccesses
              }
            }
          }
        }
      }
  }

  // Generic actions
  if (action.resource && action.resource !== 'events') {
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      const data = {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...action.data.attributes,
            ...{ __type: action.data.type },
            ...{
              parent:
                action.data.relationships && action.data.relationships.parent && action.data.relationships.parent.data
                  ? action.data.relationships.parent.data.id
                  : null
            },
            ...{
              venue:
                action.data.relationships && action.data.relationships.venue && action.data.relationships.venue.data
                  ? action.data.relationships.venue.data
                  : null
            },
            ...{
              entity:
                action.data.relationships && action.data.relationships.entity && action.data.relationships.entity.data
                  ? action.data.relationships.entity.data
                  : null
            },
            ...{ id: action.data.id }
          }
        }
      }
      if (action.data.attributes && action.data.attributes.alias) {
        data[action.data.attributes.alias] = data[action.id]
      }
      return data
    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]: value, ...remaining } = state
      return remaining
    case RECEIVE_RESOURCES:
      return action.data.reduce(
        (map, item) => {
          const current = state[item.id] || {}
          map[item.id] = {
            ...current,
            ...item.attributes,
            ...{ id: item.id },
            ...{ __type: item.type },
            ...{
              parent:
                item.relationships && item.relationships.parent && item.relationships.parent.data
                  ? item.relationships.parent.data.id
                  : current.parent
            },
            ...{
              venue:
                item.relationships && item.relationships.venue && item.relationships.venue.data
                  ? item.relationships.venue.data
                  : current.venue
            },
            ...{
              entity:
                item.relationships && item.relationships.entity && item.relationships.entity.data
                  ? item.relationships.entity.data
                  : current.entity
            }
          }
          if (item.attributes && item.attributes.alias) {
            map[item.attributes.alias] = map[item.id]
          }
          return map
        },
        { ...state }
      )
    case RECEIVE_COMMENTS:
      let comments = []
      comments = action.data.map(postData => {
        return postData.id
      })

      const existing =
        state[action.id] && state[action.id].comments && state[action.id].comments.items
          ? state[action.id].comments.items
          : []

      comments = [].concat(comments, existing).filter((value, index, self) => {
        return value && self.indexOf(value) === index
      })

      return {
        ...state,
        ...{
          [action.id]: {
            ...state[action.id],
            ...{
              comments: {
                ...state[action.id].comments,
                ...{ items: comments }
              }
            }
          }
        }
      }

    case RECEIVE_COMMENT_COUNT:
      return {
        ...state,
        ...{
          [action.id]: {
            ...state[action.id],
            ...{
              comments: {
                ...state[action.id].comments,
                ...{ count: action.count }
              }
            }
          }
        }
      }

    default:
      return state
  }
}

export const event = (
  state = {
    registration: {
      status: ''
    }
  },
  action
) => {
  switch (action.type) {
    case REQUEST_EVENT_REGISTRATION:
      return { ...state, ...{ registration: { status: 'pending' } } }
    case RECEIVE_EVENT_REGISTRATION:
      return { ...state, ...{ registration: { status: 'success' } } }
    case CLEAR_EVENT_REGISTRATION:
      return { ...state, ...{ registration: { status: '' } } }
    default:
      return state
  }
}
