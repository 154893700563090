import React from 'react'
import Button from 'react-bootstrap/Button'
import ReportModal from './ReportModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEventEntity, isEntityOwner } from '../../redux/state'

export const ReportButton = props => {
  const dispatch = useDispatch()

  const { variant, id, mode, className, size } = props

  const entity = useSelector(state => getEventEntity(state, id))

  const authorised = useSelector(state => (entity ? isEntityOwner(state, entity.id) : false))

  if (!authorised) return null

  const handleClick = e => {
    e.stopPropagation()
    dispatch(showModal('report-events', id, true))
    if (props.handleClick) {
      props.handleClick(e)
    }
  }

  const label = 'Sales Report'

  if (mode === 'icon') {
    return (
      <React.Fragment>
        <button
          title={label}
          onClick={handleClick}
          className={className}>
          <FontAwesomeIcon icon={faChartBar} />
        </button>
        <ReportModal
          id={id}
          resource="events"
        />{' '}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant={variant || 'light'}
        title={label}
        onClick={handleClick}
        className={className}
        size={size}>
        <FontAwesomeIcon
          icon={faChartBar}
          className="me-2"
        />
        <span className="text-nowrap">{label}</span>
      </Button>
      <ReportModal
        id={id}
        resource="events"
      />{' '}
    </React.Fragment>
  )
}

export default ReportButton
