import React from 'react'
import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { getEvent, getEventEntity, isEntityOwner } from '../../redux/state'
import serialize from '../../common/serialize'

export default props => {
  const { id, mode, variant = 'primary', className, size } = props

  const event = useSelector(state => getEvent(state, id))

  const entity = useSelector(state => getEventEntity(state, id))

  const authorised = useSelector(state => (entity ? isEntityOwner(state, entity.id) : false))

  if (!authorised) return null

  const handleClick = e => {
    const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const requestOptions = {
      method: 'GET',
      headers: { Accept: mime },
      credentials: 'include'
    }

    const auth = localStorage.getItem('shoppingApiToken')
    if (auth) requestOptions.headers['Authentication-Info'] = auth

    const path = '/events/' + id + '/reports/sales?subevents=1'
    const url = window.endpoint.shopping + path
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error receiving order data')
        }
        return response.blob()
      })
      .then(result => {
        const url = URL.createObjectURL(result)
        const a = $("<a style='display: none;'/>")
        a.attr('href', url)
        a.attr('download', `${event.name.replaceAll(/\W/g, '')}-Sales-${new Date().toLocaleDateString('en-GB')}.xlsx`)
        $('body').append(a)
        a[0].click()
        window.URL.revokeObjectURL(url)
        a.remove()
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  const label = 'Download sales report'

  if (mode === 'icon') {
    return (
      <React.Fragment>
        <button
          title={label}
          onClick={handleClick}
          className={className}>
          <FontAwesomeIcon icon={faCartArrowDown} />
        </button>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant={variant}
        title={label}
        onClick={handleClick}
        className={className}
        size={size}>
        <FontAwesomeIcon
          icon={faCartArrowDown}
          className="d-inline"
        />{' '}
        <span className="d-none d-md-inline text-nowrap ms-2">{label}</span>
      </Button>
    </React.Fragment>
  )
}
