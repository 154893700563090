import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement,
  Filler,
  Ticks
} from 'chart.js'
import { useEffect, useState } from 'react'
import { LineChart } from './LineChart'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEvent, fetchEventSalesReport } from '../../../redux/event/actions'
import { getEventSalesData } from '../../../redux/event/state'
import 'chartjs-adapter-date-fns'
import { enGB } from 'date-fns/locale'
import { getEvent } from '../../../redux/state'
import { getElementState } from '../../../redux/ui/state'
import { toggleUIElement } from '../../../redux/ui/actions'
import Form from 'react-bootstrap/Form'
import formatCurrency from '../../../common/format-currency'
import getDataSet from './get-dataset'
import EventTypeSelector from '../EventTypeSelector'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale, Filler)

export const SalesReportGraph = props => {
  const { id } = props

  const dispatch = useDispatch()

  const [comparisonEventId, setComparisonEventId] = useState(null)
  const [viewType, setViewType] = useState('sales')

  const cummulative = useSelector(state => getElementState(state, 'report-sales-chart-cummulative', true))
  const includeSubEvents = useSelector(state => {
    if (comparisonEventId) return false
    return getElementState(state, 'report-sales-chart-include-subevents', false)
  })
  const event = useSelector(state => getEvent(state, id))
  const sales = useSelector(state => getEventSalesData(state, includeSubEvents, id))
  const comparisonEvent = useSelector(state => (comparisonEventId ? getEvent(state, comparisonEventId) : null))
  const comparisonSales = useSelector(state =>
    comparisonEventId ? getEventSalesData(state, includeSubEvents, comparisonEventId) : null
  )

  useEffect(() => {
    if (id) {
      dispatch(fetchEventSalesReport(id, includeSubEvents, true))
    }
  }, [includeSubEvents, id])

  useEffect(() => {
    if (comparisonEventId) dispatch(fetchEventSalesReport(comparisonEventId, includeSubEvents))
  }, [comparisonEventId, includeSubEvents])

  useEffect(() => {
    if (comparisonEventId) dispatch(fetchEvent(comparisonEventId))
  }, [comparisonEventId])

  // const isComparison = Boolean(sales && comparisonSales)
  const isComparison = Boolean(comparisonEventId)

  const salesData = getDataSet(sales, event, viewType, cummulative, isComparison)

  const comparisonData =
    comparisonEvent && comparisonSales
      ? getDataSet(comparisonSales, comparisonEvent, viewType, cummulative, isComparison, { r: 153, g: 153, b: 153 })
      : null

  const currency = sales ? sales[0].currency : 'GBP'

  const options = useSelector(state => {
    const event = getEvent(state, id)
    if (!event) return null
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: false
        }
      },
      scales: {
        x: {
          // The axis for this scale is determined from the first letter of the id as `'x'`
          // It is recommended to specify `position` and / or `axis` explicitly.
          type: isComparison ? 'linear' : 'time',
          title: {
            display: true,
            text: isComparison ? 'Week' : 'Date'
          },
          adapters: {
            date: {
              locale: enGB
            }
          }
        },
        y: {
          ticks: {
            callback: function (value, index, ticks) {
              return viewType === 'admissions' ? new Intl.NumberFormat().format(value) : formatCurrency(value, currency)
            }
          }
        }
      }
    }
  })

  const data = salesData ? { datasets: [salesData] } : null

  if (data && comparisonData) data.datasets.push(comparisonData)

  return (
    <>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header
            as="h6"
            className="px-0 sales-report__header text-muted">
            <FontAwesomeIcon icon={faCog} />
          </Accordion.Header>
          <Accordion.Body className="px-0 pt-0">
            <div className="mb-2 row">
              <div className="col-6">
                <EventTypeSelector
                  id="SalesComparisonInput"
                  name="comparison"
                  event={id}
                  size="sm"
                  onChange={v => (v.length ? setComparisonEventId(v[0].id) : setComparisonEventId(null))}
                  value={comparisonEventId}
                  label="Compare with:"
                />
              </div>
              <div className="col-6">
                <Form.Group controlId="SalesReportViewInput">
                  <Form.Label className="mt-2 mb-0">Show:</Form.Label>
                  <Form.Select
                    id="SalesReportViewInput"
                    name="type"
                    size="sm"
                    onChange={e => setViewType(e.target.value)}
                    value={viewType}>
                    <option value="sales">Sales</option>
                    <option value="admissions">Admissions</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="mb-2 pb-2 d-flex border-bottom">
              <Form.Check
                type="switch"
                label="Cummulative"
                checked={cummulative}
                onChange={e => dispatch(toggleUIElement('report-sales-chart-cummulative', e.currentTarget.checked))}
              />
              <Form.Check
                type="switch"
                label="Include sub-events"
                className="ms-3"
                checked={includeSubEvents}
                onChange={e =>
                  dispatch(toggleUIElement('report-sales-chart-include-subevents', e.currentTarget.checked))
                }
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <LineChart
        id={id}
        includeSubEvents={includeSubEvents}
        options={options}
        data={data}
      />
    </>
  )
}

export default SalesReportGraph
