import { getMany } from '../crud/actions'

/**
 * Feeds
 */
export const SET_FEED_STATUS = 'SET_FEED_STATUS'
export const SET_FEED_FILTER = 'SET_FEED_FILTER'
export const SET_FEED_ORDER = 'SET_FEED_ORDER'
export const SET_FEED_SCROLL_POSITION = 'SET_FEED_SCROLL_POSITION'
export const CLEAR_FEED = 'CLEAR_FEED'
export const POPULATE_FEED = 'POPULATE_FEED'

export const setFeedStatus = (name, status, error) => {
  return {
    type: SET_FEED_STATUS,
    name,
    status,
    error
  }
}

export const setFeedFilter = (name, filter) => {
  return {
    type: SET_FEED_FILTER,
    name,
    filter
  }
}

export const setFeedOrder = (name, sort) => {
  return {
    type: SET_FEED_ORDER,
    name,
    sort
  }
}

export const setFeedScrollPosition = (name, id) => {
  return {
    type: SET_FEED_SCROLL_POSITION,
    name,
    id
  }
}

export const clearFeed = name => {
  return {
    type: CLEAR_FEED,
    name
  }
}

export const populateFeed = (name, resource, filter, sort, opts, data) => {
  return {
    type: POPULATE_FEED,
    name,
    resource,
    data,
    filter,
    opts,
    sort
  }
}

export const fetchFeed = (name, resource, filter, sort, opts = {}, force = false) => {
  return (dispatch, getState) => {
    if (!force && getState().feeds && getState().feeds[name] && getState().feeds[name].status === 'fetched') {
      return Promise.resolve(getState().feeds[name].items)
    }
    dispatch(setFeedStatus(name, 'fetching'))
    const resources = !Array.isArray(resource) ? [resource] : resource

    resources
      .reduce((p, resource) => {
        if (!resource) {
          return Promise.resolve()
        }
        opts = { ...{ page: 1 }, ...opts, ...{ filter, sort } }
        const { user } = getState()
        if (user && user.entities && user.entities.length) {
          opts.orgs = user.entities.join(',')
        }
        return dispatch(getMany(resource, opts)).then(result => {
          if (result && result.data) {
            dispatch(populateFeed(name, resource, filter, sort, opts, result.data))
            if (result.data.length === 100) {
              dispatch(fetchFeed(name, resource, filter, sort, { ...opts, ...{ page: opts.page + 1 } }, true))
            }
          }
        })
      }, Promise.resolve())
      .then(result => {
        dispatch(setFeedStatus(name, 'fetched'))
        return result
      })
      .then(result => {
        return getState().feeds[name].items
      })
      .catch(err => {
        dispatch(setFeedStatus(name, 'error', err))
      })
  }
}

export const reloadFeed = name => {
  return (dispatch, getState) => {
    if (!getState().feeds || !getState().feeds[name]) {
      return Promise.resolve(null)
    }
    dispatch(clearFeed(name))
    return dispatch(
      fetchFeed(name, getState().feeds[name].resource, getState().feeds[name].filter, getState().feeds[name].sort, {
        ...getState().feeds[name].opts,
        ...{ clear: true }
      })
    )
  }
}

export const filterFeed = (name, filter, reload = true) => {
  return (dispatch, getState) => {
    if (!getState().feeds || !getState().feeds[name]) {
      return Promise.resolve(null)
    }
    filter = { ...getState().feeds[name].filter, ...filter }
    dispatch(setFeedFilter(name, filter))
    return reload ? dispatch(reloadFeed(name)) : Promise.resolve(true)
  }
}

export const sortFeed = (name, sort, reload = true) => {
  return (dispatch, getState) => {
    if (!getState().feeds || !getState().feeds[name]) {
      return Promise.resolve(null)
    }
    dispatch(setFeedOrder(name, sort))
    return reload ? dispatch(reloadFeed(name)) : Promise.resolve(true)
  }
}
