import React from 'react'
import { useSelector } from 'react-redux'
import QuickListItem from './QuickListItem'
import { getTenantCart } from '../../redux/state'

export const QuickList = props => {
  const cart = useSelector(state => getTenantCart(state))

  let quantity = 0
  let total = 0
  let items = []

  if (cart && cart.items) {
    items = cart.items.map(item => {
      quantity += item.quantity
      total +=
        item.quantity * (item.offering.vat ? ((100 + item.offering.vat) * item.offering.net) / 100 : item.offering.net)
      return item
    })
  }

  if (!quantity) {
    return <p>Your cart is empty</p>
  }

  return (
    <div className="cart cart__list cart__list--quicklist">
      {items.map(item => {
        return (
          <QuickListItem
            item={item}
            className="mb-2"
          />
        )
      })}
    </div>
  )
}

export default QuickList
