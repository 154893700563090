import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterFeed } from '../../redux/feed/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { getFeedTags } from '../../redux/feed/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ListGroup from 'react-bootstrap/ListGroup'
import Collapse from 'react-bootstrap/Collapse'

const TagFilterList = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const { feed, multiple = true, tags = [], className = '' } = props

  const selected = useSelector(state => getFeedTags(state, feed))

  if (!tags.length) return null

  const handleChange = (name, checked) => {
    let selection = selected || []
    if (checked) {
      selection = multiple === false ? [name] : [...selection, name]
    } else {
      selection.splice(selection.indexOf(name), 1)
    }
    selection = selection.filter((v, i, a) => a.indexOf(v) === i)

    dispatch(filterFeed(feed, { tags: selection }))
    const search = new URLSearchParams(location.search)
    if (selection && selection.length) {
      search.set('tags', selection.join(','))
    } else {
      search.delete('tags')
    }
    history.push(`${location.pathname}?${search.toString()}`)
  }

  const clearTags = e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(filterFeed(feed, { tags: [] }))
    const search = new URLSearchParams(location.search)
    search.delete('tags')
    history.push(`${location.pathname}?${search.toString()}`)
    setOpen(false)
  }

  const ClearButton = props => {
    const { selected, onClick, className = '' } = props
    if (!selected || !selected.length) return null
    return (
      <button
        className={`p-0 border-0 bg-none ${className}`}
        onClick={onClick}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
    )
  }

  return (
    <div className={className}>
      <button
        className={`d-block p-2 w-100 text-start ${
          open ? 'bg-light border-top border-start border-end rounded-top' : ''
        }`}
        onClick={() => setOpen(!open)}>
        <FontAwesomeIcon
          icon={faFilter}
          className="text-small me-2"
        />{' '}
        Filter
        <span className="d-md-none ms-1 fw-bold">{selected && selected.length ? `(${selected.length})` : ''}</span>
        <span className="d-none d-md-inline ms-1 fst-italic">
          {selected && selected.length ? `(${(selected || []).join(', ')})` : ''}
        </span>
        <ClearButton
          selected={selected}
          onClick={clearTags}
          className="text-small ms-2"
        />
      </button>
      <Collapse
        in={open}
        className="p-0">
        <ListGroup
          variant="flush"
          className="border rounded-bottom">
          {tags.map((tag, index) => {
            const isSelected = (selected || []).indexOf(tag) !== -1
            return (
              <ListGroup.Item
                action
                key={index}
                label={tag}
                onClick={() => handleChange(tag, !isSelected)}
                variant={isSelected ? 'secondary' : null}>
                {isSelected ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="me-2 text-small"
                  />
                ) : null}{' '}
                {tag}
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Collapse>
    </div>
  )
}

export default TagFilterList
