import { getEvent } from '../state'

/**
 * Returns true if an event is loaded
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
export const eventIsLoaded = (state, id) => {
  const { events } = state
  return Boolean(events[id] && events[id].created)
}

export const getEventStock = (state, id) => {
  const event = getEvent(state, id)
  if (!event) return null
  return (event.offerings || []).reduce((agg, offering) => {
    if (offering.stock !== undefined && offering.stock !== null) {
      return agg ? agg + offering.stock : offering.stock
    }
    return agg
  }, null)
}

export const getEventSalesData = (state, includeSubEvents, id) => {
  const key = includeSubEvents ? '__allSales' : '__sales'
  const event = getEvent(state, id)
  if (!event) return null
  return event[key] || null
}

export const getEventSalesSummary = (state, includeSubEvents, id) => {
  const key = includeSubEvents ? '__allSalesSummary' : '__salesSummary'
  const event = getEvent(state, id)
  if (!event) return null
  return event[key] || null
}

export const getEventOfferingCategories = (state, id) => {
  const event = getEvent(state, id)
  if (!event) return []
  if (Array.isArray(event.offerTypes) && event.offerTypes.length) return event.offerTypes
  return Object.keys(
    (event.offerings || []).reduce((agg, offering) => {
      if (offering.category) agg[offering.category] = offering.category
      return agg
    }, {})
  )
}

export const getEventFeedName = (state, id) => {
  const event = getEvent(state, id)
  return `event-${event ? event.alias || event.id : id}`
}

export const getTicketedEvents = (state, id) => {
  const event = getEvent(state, id)
  if (event.__ticketed && event.__ticketed.length) {
    return event.__ticketed.map(child => getEvent(state, child))
  }
  return null
}

export const getEventAccesses = (state, id) => {
  const event = getEvent(state, id)
  if (event.__accesses && event.__accesses.length) {
    return event.__accesses
  }
  return null
}
