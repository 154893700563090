import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEvent, isEventAdmin } from '../../../redux/state'
import { useState } from 'react'
import { fetchTicketedEvents, inviteEventGuests } from '../../../redux/event/actions'
import { showModal } from '../../../redux/ui/actions'
import Alert from 'react-bootstrap/Alert'

export default function SaleForm(props) {
  const { offering } = props

  const dispatch = useDispatch()

  const currentUser = useSelector(state => getCurrentUser(state))

  const authorised = useSelector(state => isEventAdmin(state, offering?.resource?.id))

  const event = useSelector(state => getEvent(state, offering?.resource?.id))

  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(false)

  const stock = offering.stock !== undefined && offering.stock !== null ? offering.stock : null

  const setAdjustedQuantity = value => {
    const adjusted = stock !== null ? Math.min(value, stock) : value
    setQuantity(adjusted)
  }

  if (!authorised || !event) return null

  if (stock !== null && stock <= 0) return null

  const onSubmit = e => {
    setError('')
    e.preventDefault()
    if (!email) {
      setError('Please supply a valid email address')
      return false
    }
    if (!firstName) {
      setError('Please supply a first name')
      return false
    }
    if (!surname) {
      setError('Please supply a surname')
      return false
    }
    if (!quantity) {
      setError('Please set a quantity')
      return false
    }
    const data = {
      resource: offering.resource,
      entity: event.entity.id,
      offering,
      sender: {
        name: currentUser.username
      },
      mode: '',
      users: [
        {
          email,
          firstName,
          surname,
          quantity
        }
      ],
      file: null
    }
    setDisabled(true)
    dispatch(inviteEventGuests(offering.resource.id, data))
      .then(result => {
        setDisabled(false)
        setTimeout(() => dispatch(fetchTicketedEvents(offering.resource.id, true)), 1500)
        dispatch(showModal(`boxoffice-sale-events`, `${offering?.resource?.id}-${offering?.id}`, false))
      })
      .catch(err => {
        setDisabled(false)
        setError(err.message)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      disabled={disabled}>
      <Form.Group
        className="mb-3"
        controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          name="email"
          required={true}
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId="formFirstName">
        <Form.Label>First name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          required={true}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          placeholder="First name"
        />
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId="formSurname">
        <Form.Label>Surname</Form.Label>
        <Form.Control
          type="text"
          name="surname"
          required={true}
          value={surname}
          onChange={e => setSurname(e.target.value)}
          placeholder="Surname"
        />
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId="formQuantity">
        <Form.Label>Quantity {stock !== null ? `(${stock} available)` : ''}</Form.Label>
        <Form.Control
          type="number"
          name="quantity"
          required={true}
          value={quantity}
          onChange={e => setAdjustedQuantity(e.target.value)}
          placeholder="Quantity"
        />
      </Form.Group>
      {error ? (
        <Alert
          className="my-2"
          variant="danger">
          {error}
        </Alert>
      ) : null}
      <p className="mb-0 d-flex justify-content-between">
        <span className="h4">
          £
          {((quantity * offering.net * (100 + offering.vat)) / 100).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </span>
        <Button
          variant={`${disabled ? 'secondary' : 'primary'}`}
          type="submit"
          disabled={disabled}>
          Confirm sale
        </Button>
      </p>
    </Form>
  )
}
