import React, { useState } from 'react'
import ListItem from './ListItem'
import EditForm from './EditForm'
import arrayMove from '../../common/array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

const List = props => {
  const { entity, data, handleChange: onChange, productType, event, container, hideInactive = false } = props

  const [stored, setStored] = useState(data || [])
  const [edit, setEdit] = useState(null)
  const [clone, setClone] = useState(null)

  const handleDelete = index => {
    let data = [...stored]
    if (data[index].id) {
      data[index].active = false
    } else {
      data = data.filter((item, i) => {
        return i !== index
      })
    }
    setStored(data)
    handleChange(data)
  }

  const handleSave = offering => {
    return new Promise((resolve, reject) => {
      const data = [...stored]
      if (edit !== null) {
        data[edit] = offering
      } else {
        data.push(offering)
      }
      setStored(data)
      setEdit(null)
      setClone(null)
      handleChange(data)
      resolve(true)
    })
  }

  const handleChange = data => {
    if (onChange) {
      onChange(data)
    }
  }

  const handleEdit = index => {
    setEdit(index)
  }

  const handleClone = index => {
    if (stored[index]) {
      const { _id, id, ...rest } = stored[index]
      setClone(rest)
      const element = document.getElementById(`TicketForm-${event}`)
      element.scrollIntoView()
    }
  }

  const handleCancel = () => {
    setEdit(null)
    setClone(null)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [...updated] = stored
    arrayMove(updated, oldIndex, newIndex)
    setStored(updated)
    handleChange(updated)
  }

  const getListItem = (index, item, className = '') => {
    if (hideInactive && !item.active) return <p className="m-0 font-size-sm"></p>
    return (
      <ListItem
        key={index}
        index={index}
        data={item}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleClone={handleClone}
        className={`mb-2 ${className}`}
      />
    )
  }

  const SortableListItem = SortableElement(({ idx, item }) => getListItem(idx, item, 'sortableItem cursor-grab'))

  const SortableList = SortableContainer(({ data }) => {
    return (
      <div key="list sortableContainer">
        {data.map((item, index) => (
          <SortableListItem
            key={index}
            index={index}
            idx={index}
            item={item}
          />
        ))}
      </div>
    )
  })

  const getList = () => {
    if (edit !== null) {
      return (
        <div key="list">
          {stored.map((item, index) => {
            if (edit !== null && edit === index) {
              return (
                <EditForm
                  entity={entity}
                  event={event}
                  key={'Item' + edit}
                  editMode={true}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                  productType={productType || 'ticket'}
                  className="mb-4"
                  data={stored[edit]}
                />
              )
            }
            return getListItem(index, item)
          })}
        </div>
      )
    }
    return (
      <SortableList
        data={stored}
        onSortEnd={onSortEnd}
        helperClass="sortableHelper"
        axis="y"
        helperContainer={container ? container.current : null}
        distance={1}
      />
    )
  }

  return (
    <>
      <div key="list">{getList()}</div>
      {edit !== null ? null : (
        <>
          <h4
            key="title"
            className="border-top pt-3 mt-3">
            Add Offering
          </h4>
          <EditForm
            id={`TicketForm-${event}`}
            key={clone ? 'clone' : 'new'}
            handleSave={handleSave}
            handleCancel={handleCancel}
            productType={productType || 'ticket'}
            data={clone}
            event={event}
          />
        </>
      )}
    </>
  )
}

export default List
