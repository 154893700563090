import React, { useEffect, useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import OffersInput from './OffersInput'
import useFormGetSet from '../../hooks/use-form-get-set'
import EventOfferingCategoryInput from '../event/EventOfferingCategoryInput'

const defaultData = {
  name: '',
  access: {
    lifespan: {
      value: 0,
      unit: 'day'
    }
  },
  net: 0,
  vat: 0,
  gifting: {
    enabled: false,
    reduction: 0
  },
  stock: null,
  active: true
}

export const EditForm = props => {
  const {
    id,
    data: inheritedData,
    handleSave,
    handleCancel: onCancel,
    onValidate,
    productType,
    entity,
    className = '',
    event,
    editMode = false
  } = props

  const [validated, setValidated] = useState(false)
  const [changed, setChanged] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [serverError, setServerError] = useState('')
  const [confirmationVisible, setConfirmationVisible] = useState(false)

  const ref = useRef()

  const [stateData, setStateData, getValue, setValue, setValues] = useFormGetSet(inheritedData || defaultData, () =>
    setChanged(true)
  )

  useEffect(() => {
    if (inheritedData) {
      setStateData(inheritedData)
    }
  }, [inheritedData])

  const handleCancel = event => {
    event.preventDefault()
    event.stopPropagation()
    setStateData(defaultData)
    if (onCancel) {
      onCancel()
    }
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    saveData(ref.current)
  }

  const handleInputChange = event => {
    let name, value
    name = event.target.name
    value = event.target.value
    if ($(event.target).attr('type') === 'number' && value !== '' && !isNaN(parseFloat(value))) {
      value = Number(value)
    }
    if (event.target.type === 'checkbox') {
      value = Boolean(event.target.checked)
    }
    setValue(name, value)
  }

  const saveData = form => {
    $('#OfferingNameInput', $(ref.current)).attr('required', 'required')
    $('#OfferingNetInput', $(ref.current)).attr('required', 'required')
    var passed = form.checkValidity()
    setValidated(true)
    if (onValidate) {
      onValidate(form)
    }
    if (!passed) {
      return
    }
    if (handleSave) {
      handleSave(stateData)
        .then(result => {
          $('#OfferingNameInput', $(ref.current)).removeAttr('required', 'required')
          $('#OfferingNetInput', $(ref.current)).removeAttr('required', 'required')
          setValidated(false)
          setChanged(false)
          setStateData(defaultData)
          setFeedbackMessage('')
        })
        .catch(err => {
          setServerError(err.message)
        })
    }
  }

  const getProductSection = () => {
    if (productType !== 'product') {
      return null
    }

    return (
      <React.Fragment>
        <Form.Group controlId="OfferingFreeShippingInput">
          <Form.Check
            type="checkbox"
            name="shipping_exempt"
            onChange={handleInputChange}
            label="Free shipping"
            checked={getValue('shipping.exempt')}
          />
        </Form.Group>
      </React.Fragment>
    )
  }

  const getTicketSection = () => {
    if (productType !== 'ticket') {
      return null
    }

    return (
      <React.Fragment>
        <Form.Group controlId="OfferingTermsInput">
          <Form.Label className="mt-2 mb-0">Terms</Form.Label>
          <Form.Control
            name="terms"
            as="textarea"
            rows={2}
            onChange={handleInputChange}
            placeholder="Terms and conditions applied to the ticket"
            value={getValue('terms')}
          />
        </Form.Group>

        <Form.Group controlId="OfferingLifespanInput">
          <Form.Label className="mt-2 mb-0 d-block">Lifespan</Form.Label>
          <Form.Text className="text-muted">
            Controls how long the user has access to the event from the moment they first access it. Leave blank for
            unlimited access.
          </Form.Text>
          <Row>
            <Col>
              <Form.Control
                name="access_lifespan_value"
                onChange={handleInputChange}
                placeholder="The lifespan of the access granted by the ticket"
                value={getValue('access.lifespan.value')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Control
                as="select"
                name="access_lifespan_unit"
                onChange={handleInputChange}
                value={getValue('access.lifespan.unit') || 'day'}>
                <option value="minute">Minutes</option>
                <option value="hour">Hours</option>
                <option value="day">Days</option>
                <option value="month">Months</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <OffersInput
          entity={entity}
          name="offers"
          onChange={setValue}
          offers={getValue('offers')}
        />

        <Form.Group controlId="OfferingGiftingInput">
          <Form.Label className="mt-2 mb-0 d-block">Gifting</Form.Label>
          <Form.Text className="text-muted">
            Allows a purchaser to gift access to someone else at the price they purchased at, reduced by a set
            percentage
          </Form.Text>
          <Row>
            <Col xs={1}>
              <Form.Check
                type="checkbox"
                name="gifting_enabled"
                className="mt-1"
                aria-label="Allow gifting"
                value="1"
                onChange={handleInputChange}
                checked={getValue('gifting.enabled')}
              />
            </Col>
            <Col xs={11}>
              <Form.Control
                name="gifting_reduction"
                onChange={handleInputChange}
                placeholder="Reduction (as a percentage) applied to the gift"
                value={getValue('gifting.reduction')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="OfferingInstructionsInput">
          <Form.Label className="mt-2 mb-0">Joining instructions</Form.Label>
          <Form.Control
            name="instructions"
            as="textarea"
            rows={2}
            onChange={handleInputChange}
            placeholder="Special instructions for this specific ticket"
            value={getValue('instructions')}
          />
          <Form.Text className="text-muted">These instructions will be e-mailed with the buyer's ticket.</Form.Text>
        </Form.Group>
      </React.Fragment>
    )
  }

  const errorStyle = {
    display: serverError === '' ? 'none' : 'block'
  }

  const feedbackStyle = {
    display: feedbackMessage === '' ? 'none' : 'block'
  }

  const priceWarning = editMode ? (
    <Form.Text className="text-muted">The price of an existing offering can't be changed</Form.Text>
  ) : null

  return (
    <Form
      id={id}
      ref={ref}
      novalidate="true"
      validated={validated}
      onSubmit={handleSubmit}
      className={className}>
      <Form.Group controlId="OfferingNameInput">
        <Form.Label className="mt-2 mb-0">Description</Form.Label>
        <Form.Control
          name="name"
          onChange={handleInputChange}
          placeholder="A description of the product/offering"
          value={getValue('name')}
        />
        <Form.Control.Feedback type="invalid">Please add a description of the offering</Form.Control.Feedback>
      </Form.Group>

      <EventOfferingCategoryInput
        name="category"
        onChange={setValue}
        value={getValue('category')}
        event={event}
        id="OfferingCategoryInput"
      />

      <Form.Group controlId="OfferingNetInput">
        <Row>
          <Col>
            <Form.Label className="mt-2 mb-0">Net price</Form.Label>
            <Form.Control
              name="net"
              onChange={handleInputChange}
              placeholder="The price of the offering, not including tax"
              disabled={editMode}
              value={getValue('net')}
              type="number"
            />
            <Form.Control.Feedback type="invalid">Please add a net price</Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label className="mt-2 mb-0">VAT</Form.Label>
            <Form.Control
              name="vat"
              onChange={handleInputChange}
              placeholder="VAT (as a percentage) applied to the sale"
              value={getValue('vat')}
              type="number"
            />
            <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label className="mt-2 mb-0">Admits</Form.Label>
            <Form.Control
              name="admissions"
              onChange={handleInputChange}
              placeholder="The number of people the ticket admits"
              value={getValue('admissions')}
              type="number"
            />
            <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
          </Col>
        </Row>
        {priceWarning}
      </Form.Group>

      <Form.Group controlId="OfferingStockInput">
        <Form.Label className="mt-2 mb-0">Stock</Form.Label>
        <Form.Control
          type="number"
          name="stock"
          onChange={handleInputChange}
          placeholder="The amount of this offering you have available (leave blank for unlimited)"
          value={getValue('stock')}
        />
        <Form.Control.Feedback type="invalid">Please enter a valid number</Form.Control.Feedback>
      </Form.Group>

      {getTicketSection()}
      {getProductSection()}

      <Form.Group
        controlId="OfferingActiveInput"
        className="mt-3">
        <Form.Check
          type="checkbox"
          name="active"
          onChange={handleInputChange}
          label="Active"
          checked={getValue('active')}
        />
      </Form.Group>

      <Form.Group style={errorStyle}>
        <Form.Control.Feedback type="invalid">{serverError}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={feedbackStyle}>
        <Form.Control.Feedback type="valid-feedback">{feedbackMessage}</Form.Control.Feedback>
      </Form.Group>

      <div className="text-end">
        <Button
          value="cancel"
          variant="secondary"
          className="me-2"
          size="sm"
          onClick={handleCancel}>
          {editMode ? 'Cancel' : 'Reset'}
        </Button>
        <Button
          value="save"
          variant="primary"
          onClick={handleSubmit}
          size="sm">
          {editMode ? 'Update' : 'Add'}
        </Button>
      </div>
    </Form>
  )
}

export default EditForm
