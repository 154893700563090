import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import {
  faImage,
  faInfoCircle,
  faCalendarDay,
  faTicketAlt,
  faThList,
  faUnlockAlt,
  faBookReader,
  faPeopleCarry,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import ImageSelector from '../form/ImageSelector'
import ImagesSelector from '../form/ImagesSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import { useDispatch, useSelector } from 'react-redux'
import {
  postEvent,
  patchEvent,
  fetchEvent,
  deleteEvent,
  reloadEventFeed,
  setEventFeedSort
} from '../../redux/event/actions'
import { getEvent, isEventOwner } from '../../redux/state'
import { showModal } from '../../redux/ui/actions'
import Section from '../form/Section'
import ConfirmationModal from '../form/ConfirmationModal'
import Accordion from 'react-bootstrap/Accordion'
import OfferingList from '../offering/List'
import EventSelector from './EventSelector'
import EntitySelector from '../entity/EntitySelector'
import VenueSelector from '../venue/VenueSelector'
import { adminElementsAreLoaded } from '../../redux/user/state'
import Spinner from 'react-bootstrap/Spinner'
import { eventIsLoaded } from '../../redux/event/state'
import useFormGetSet from '../../hooks/use-form-get-set'
import TicketSyncButton from './TicketSyncButton'

export const EditForm = props => {
  const dispatch = useDispatch()

  const {
    id,
    event,
    entity,
    onValidate,
    handleSaveSuccess: onSuccess,
    handleSaveError: onError,
    handleClose,
    modalBody,
    serverError
  } = props

  const defaultData = {
    name: '',
    alias: '',
    url: '',
    parent: event || null,
    entity: entity ? { id: entity } : {},
    logo: '',
    image: '',
    details: {
      url: '',
      text: ''
    },
    datetime: null,
    visibility: 'private',
    access: 'registration',
    description: '',
    tags: []
  }

  const storedData = useSelector(state => getEvent(state, id))
  const owner = useSelector(state => isEventOwner(state, id))
  // const feed = useSelector(state => {
  //   const { feeds } = state
  //   return feeds['event-' + id] ? 'event-' + id : null
  // })
  const entities = useSelector(state => {
    const { user, entities } = state
    if (user && user.entities && Array.isArray(user.entities)) {
      return user.entities.map((id, index) => ({ ...state.entities[id], ...{ id } }))
    }
    return null
  })

  const [validated, setValidated] = useState(false)
  const [aliasFeedback, setAliasFeedback] = useState(`Please enter the event's alias.`)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [showInactiveTickets, setShowInactiveTickets] = useState(false)

  const [data, setData, getValue, setValue, setValues] = useFormGetSet(storedData || defaultData, () =>
    setValidated(false)
  )

  const dataLoaded = useSelector(
    state => id === 'new' || (eventIsLoaded(state, id) && (!data || !data.parent || eventIsLoaded(state, data.parent)))
  )
  const adminElementsLoaded = useSelector(state => adminElementsAreLoaded(state))

  const ref = useRef()

  useEffect(() => {
    if (event) {
      setValue('parent', event)
    }
  }, [event])

  useEffect(() => {
    if (entity) {
      setValue('entity', entity ? { id: entity, type: 'entities' } : null)
    }
  }, [entity])

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(fetchEvent(id, true))
    }
    $('.card input, .card select, .card textarea', $(ref.current)).first().trigger('focus')
  }, [])

  const handleInputChange = event => {
    let { name, value, type, checked } = event.target

    if (type === 'datetime-local') {
      value = localToUTC(value)
    } else if (type === 'checkbox') {
      value = Boolean(checked)
    }

    if (name === 'endTime') {
      name = 'duration'
      if (data.datetime) {
        value = Math.max(0, (new Date(value).getTime() - new Date(data.datetime).getTime()) / 60000)
      } else {
        value = ''
      }
    }

    if (name === 'alias') {
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    if (name === 'entity' && value && entities[value]) {
      value = { id: value, name: entities[value].name, alias: entities[value].alias }
    }

    const values = { [name]: value }

    if (name === 'name' && !storedData) {
      values.alias = value.replace(/\W+/g, '-').toLowerCase()
    }

    if (name === 'datetime') {
      if (value && data.datetime && data.duration) {
        const endTime = new Date(new Date(data.datetime).getTime() + data.duration * 60000)
        values.duration = Math.max(0, (endTime.getTime() - new Date(value).getTime()) / 60000)
      } else if (!value) {
        values.duration = ''
      }
    }

    const updated = setValues(values)

    if (updated.alias) {
      validateAlias(updated.alias).then(success => {
        setValidated(!success)
        setAliasFeedback(success ? "Please enter the event's alias." : 'This alias is already in use.')
        $('#EventForm input[name="alias"]')
          .get(0)
          .setCustomValidity(success ? '' : 'Invalid field')
      })
    }
  }

  const handleTagChange = (tags, name = 'tags') => {
    tags = tags.map(tag => tag.text)
    setValue(name, tags)
  }

  const handleOfferingChange = data => {
    return new Promise((resolve, reject) => {
      setValue('offerings', data)
      resolve(true)
    })
  }

  const localToUTC = str => {
    if (!str) return str
    const asUTC = new Date(`${str}Z`)
    return new Date(asUTC.getTime() + asUTC.getTimezoneOffset() * 60000).toISOString()
  }

  const getStartTime = () => {
    if (data.datetime) {
      const d = new Date(data.datetime)
      return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0, -1)
    }
    return ''
  }

  const getEndTime = () => {
    if (data.datetime && data.duration) {
      const d = new Date(data.datetime)
      return new Date(d.getTime() + (data.duration - d.getTimezoneOffset()) * 60000).toISOString().slice(0, -1)
    }
    return ''
  }

  const validateAlias = alias => {
    if (data && data.alias && data.alias === alias) {
      return Promise.resolve(true)
    }
    return new Promise((resolve, reject) => {
      const url = window.endpoint.events + '/events/' + alias + '/existence'
      fetch(url)
        .then(response => {
          if (response.ok) {
            throw new Error('Alias exists')
          }
          resolve(true)
        })
        .catch(error => {
          resolve(false)
        })
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    saveData(event.currentTarget, event.nativeEvent.submitter.value)
  }

  const saveData = (form, action) => {
    var passed = form.checkValidity()
    setValidated(true)

    if (onValidate) {
      onValidate(form)
    }
    if (!passed) {
      let invalid = form.querySelectorAll(':invalid')
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const updated = { ...data }
    if (entities && Object.keys(entities).length && (!updated.entity || !updated.entity.id)) {
      const entityID = Object.keys(entities)[0]
      const entity = entities[entityID]
      updated.entity = {
        id: entityID,
        alias: entity.alias,
        name: entity.name
      }
    }
    updated.active = 1
    // Submit post
    Promise.resolve()
      .then(() => {
        if (storedData && updated.action !== 'new') {
          return dispatch(patchEvent(updated))
        }
        delete updated.id
        delete updated.action
        return dispatch(postEvent(updated))
      })
      .then(result => {
        dispatch(setEventFeedSort(data.id, data.feed?.sort))
        if (/\bclose\b/.test(action)) {
          if (updated.parent) {
            dispatch(reloadEventFeed(updated.parent))
          }
          return dispatch(showModal('events', id, false))
        } else {
          handleSaveSuccess(result)
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  const handleSaveSuccess = result => {
    setValidated(false)
    setFeedbackMessage('Event saved successfully')
    dispatch(reloadEventFeed(data.id))
    //$('form#EventForm').trigger("reset")
    if (onSuccess) {
      onSuccess(result)
    }
  }

  const handleSaveError = err => {
    setValidated(false)

    if (onError) {
      onError(err)
    }
  }

  const handleDelete = () => {
    if (data && data.id) {
      dispatch(deleteEvent(data.id)).then(result => {
        handleClose()
        if (data.parent) {
          dispatch(reloadEventFeed(data.parent))
        }
      })
    }
    hideConfirmation()
  }

  const hideConfirmation = () => {
    setConfirmationVisible(false)
  }

  const showConfirmation = () => {
    setConfirmationVisible(true)
  }

  const getTicketPanel = (key = '7') => {
    if (!owner) {
      return null
    }

    return (
      <Section
        title="Tickets"
        eventKey={key}
        className="text-secondary"
        icon={faTicketAlt}>
        <Form.Group controlId="EventTicketLabelInput">
          <Form.Label className="mt-2 mb-0">Label</Form.Label>
          <Form.Control
            name="feed_ticket_label"
            className="mb-3"
            onChange={handleInputChange}
            placeholder="A label for the 'buy tickets' button"
            value={getValue('feed.ticket.label')}
          />
        </Form.Group>

        <Form.Group
          controlId="EventTicketInstructionsInput"
          className="mb-2">
          <Form.Label className="mt-2 mb-0">Joining instructions</Form.Label>
          <Form.Control
            name="instructions"
            as="textarea"
            rows="3"
            onChange={handleInputChange}
            placeholder="Joining instructions for the event"
            value={getValue('instructions')}
          />
          <Form.Text className="text-muted">These instructions will be e-mailed with the buyer's ticket.</Form.Text>
        </Form.Group>

        <Form.Group
          controlId="EventOfferTypesInput"
          className="mb-4">
          <Form.Label className="mt-2 mb-0">Categories</Form.Label>
          <TagSelector
            tags={data && data.offerTypes ? data.offerTypes.map(type => ({ id: type, text: type })) : []}
            handleChange={types => handleTagChange(types, 'offerTypes')}
          />
        </Form.Group>

        <Form.Group controlId="InactiveTicketToggle">
          <Form.Check
            type="checkbox"
            onChange={e => setShowInactiveTickets(e.target.checked)}
            label="Show inactive tickets"
            checked={showInactiveTickets}
          />
        </Form.Group>

        <OfferingList
          data={data ? data.offerings : []}
          entity={data?.entity?.id}
          event={data?.id}
          container={ref}
          handleChange={handleOfferingChange}
          hideInactive={!showInactiveTickets}
        />

        <p>
          <TicketSyncButton id={data?.id} />
        </p>
      </Section>
    )
  }

  if (!adminElementsLoaded || !dataLoaded) {
    return (
      <div className="text-center py-5">
        <Spinner
          animation="border"
          role="status"
          variant="secondary"
        />
      </div>
    )
  }

  const errorStyle = {
    display: serverError === '' ? 'none' : 'block'
  }

  const feedbackStyle = {
    display: feedbackMessage === '' ? 'none' : 'block'
  }

  const deleteButton = data ? (
    <Button
      variant="danger"
      type="button"
      className="ms-2"
      onClick={showConfirmation}>
      Delete
    </Button>
  ) : null

  const headerClass = 'text-secondary'

  let tags = []
  if (data && data.tags) {
    tags = data.tags.map(tag => {
      return { id: tag, text: tag }
    })
  }

  let foyerTags = []
  if (data && data.foyer && data.foyer.tags) {
    foyerTags = data.foyer.tags.map(tag => {
      return { id: tag, text: tag }
    })
  }

  return (
    <Form
      ref={ref}
      novalidate="true"
      validated={validated}
      onSubmit={handleSubmit}
      id="EventForm"
      className="bg-white py-1 px-2 pb-3">
      <Accordion defaultActiveKey="0">
        <Section
          title="Event Settings"
          eventKey="0"
          className={headerClass}
          icon={faCalendarDay}>
          <Form.Group controlId="EventNameInput">
            <Form.Label className="mt-2 mb-0">Name</Form.Label>
            <Form.Control
              name="name"
              onChange={handleInputChange}
              placeholder="The event's title"
              value={getValue('name')}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter the event's name</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EventAliasInput">
            <Form.Label className="mt-2 mb-0">Alias</Form.Label>
            <Form.Control
              name="alias"
              onChange={handleInputChange}
              placeholder="The event's alias"
              value={getValue('alias')}
              required
            />
            <Form.Control.Feedback type="invalid">{aliasFeedback}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EventURLInput">
            <Form.Label className="mt-2 mb-0">Domain</Form.Label>
            <Form.Control
              name="url"
              onChange={handleInputChange}
              placeholder="The event's domain"
              value={getValue('url')}
              type="text"
            />
            <Form.Control.Feedback type="invalid">This must be a vaild domain</Form.Control.Feedback>
            <Form.Text className="text-muted">
              If the event is accessed on its own domain, enter it here. Note that this wi ll need to be configured by
              Zarucchi Support.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EventDateTimeInput">
            <Form.Label className="mt-2 mb-0">Start</Form.Label>
            <Form.Control
              name="datetime"
              type="datetime-local"
              onChange={handleInputChange}
              placeholder="The event's start time"
              value={getStartTime()}
            />
          </Form.Group>

          <Form.Group controlId="EventEndTimeInput">
            <Form.Label className="mt-2 mb-0">Finish</Form.Label>
            <Form.Control
              name="endTime"
              type="datetime-local"
              onChange={handleInputChange}
              placeholder="The event's finish time"
              value={getEndTime()}
            />
          </Form.Group>

          <Form.Group controlId="EventPerformerInput">
            <Form.Label className="mt-2 mb-0">Performer</Form.Label>
            <Form.Control
              name="performer_name"
              onChange={handleInputChange}
              placeholder="The performer(s) of the event"
              value={getValue('performer.name')}
            />
          </Form.Group>

          <VenueSelector
            id="EventVenueIdInput"
            onChange={handleInputChange}
            label="Venue"
            name="venue_id"
            value={getValue('venue.id') || (data && data.venue ? data.venue.id : null)}
          />

          <EventSelector
            id="EventEventInput"
            name="parent"
            event={data ? data.id : null}
            onChange={handleInputChange}
            value={getValue('parent') || (data ? data.parent : null)}
            label="Parent event"
          />

          <EntitySelector
            id="EventOrganisationInput"
            name="entity_id"
            onChange={handleInputChange}
            value={getValue('entity.id') || (data && data.entity ? data.entity.id : null)}
            label="Organisation"
          />
        </Section>

        <Section
          title="Display Settings"
          eventKey="1"
          className={headerClass}
          icon={faImage}>
          <Form.Group controlId="EventLandingInput">
            <Form.Label className="mt-2 mb-0">Landing page</Form.Label>
            <Form.Control
              as="select"
              name="landing"
              onChange={handleInputChange}
              value={getValue('landing')}>
              <option value="feed">Feed</option>
              <option value="foyer">Foyer</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="EventStraplineInput">
            <Form.Label className="mt-2 mb-0">Strapline</Form.Label>
            <Form.Control
              name="strapline"
              onChange={handleInputChange}
              placeholder="The event's strapline"
              value={getValue('strapline')}
            />
          </Form.Group>

          <Form.Group controlId="EventImageInput">
            <Form.Label className="mt-2 mb-0">Main image</Form.Label>
            <div
              className="input-group align-items-start"
              id="EventImageInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('image')}
                name="image"
                type="image"
                handleChange={setValue}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="EventPrecisInput">
            <Form.Label className="mt-2 mb-0">Precis</Form.Label>
            <Form.Control
              name="precis"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="A precis of the event"
              value={getValue('precis')}
            />
            <Form.Text className="text-muted">Used for listings and the event 'cheatsheet'</Form.Text>
          </Form.Group>

          <Form.Group controlId="EventDescriptionInput">
            <Form.Label className="mt-2 mb-0">Description</Form.Label>
            <Form.Control
              name="description"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="The event's description"
              value={getValue('description')}
            />
          </Form.Group>

          <Form.Group controlId="EventLogoInput">
            <Form.Label className="mt-2 mb-0">Logo</Form.Label>
            <div
              className="input-group align-items-start"
              id="EventLogoInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('logo')}
                name="logo"
                type="logo"
                handleChange={setValue}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="EventRatingInput">
            <Form.Label className="mt-2 mb-0">Rating</Form.Label>
            <Form.Control
              as="select"
              name="rating"
              onChange={handleInputChange}
              value={getValue('rating')}>
              <option value="">None</option>
              <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
              <option value="4">&#9733; &#9733; &#9733; &#9733;</option>
              <option value="3">&#9733; &#9733; &#9733;</option>
              <option value="2">&#9733; &#9733;</option>
              <option value="1">&#9733;</option>
            </Form.Control>
          </Form.Group>
        </Section>

        <Section
          title="Details Settings"
          eventKey="2"
          className={headerClass}
          icon={faInfoCircle}>
          <Form.Group controlId="EventDetailsURLInput">
            <Form.Label className="mt-2 mb-0">URL</Form.Label>
            <Form.Control
              name="details_url"
              onChange={handleInputChange}
              placeholder="A link to further details or tickets for the event"
              value={getValue('details.url')}
              type="url"
            />
            <Form.Control.Feedback type="invalid">
              This must be a full URL, including http:// or https:// at the beginning
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EventDetailsTextInput">
            <Form.Label className="mt-2 mb-0">Label</Form.Label>
            <Form.Control
              name="details_text"
              onChange={handleInputChange}
              placeholder="The label for the 'more details' button - default 'More details'"
              value={getValue('details.text')}
            />
          </Form.Group>
        </Section>

        <Section
          title="FAQs"
          eventKey="3"
          className={headerClass}
          icon={faQuestionCircle}>
          <Form.Group controlId="EventFAQsURLInput">
            <Form.Label className="mt-2 mb-0">URL</Form.Label>
            <Form.Control
              name="faqs_url"
              onChange={handleInputChange}
              placeholder="A link to FAQs relating to the event"
              value={getValue('faqs.url')}
              type="url"
            />
            <Form.Control.Feedback type="invalid">
              This must be a full URL, including http:// or https:// at the beginning
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EventFAQsTextInput">
            <Form.Label className="mt-2 mb-0">Label</Form.Label>
            <Form.Control
              name="faqs_text"
              onChange={handleInputChange}
              placeholder="The label for FAQs link - default 'FAQs'"
              value={getValue('faqs.text')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Access Settings"
          eventKey="4"
          className={headerClass}
          icon={faUnlockAlt}>
          <Form.Group controlId="EventVisiblityInput">
            <Form.Label className="mt-2 mb-0">Visibility</Form.Label>
            <Form.Control
              as="select"
              name="visibility"
              onChange={handleInputChange}
              value={getValue('visibility')}>
              <option value="public">Public</option>
              <option value="unlisted">Unlisted</option>
              <option value="private">Private</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="EventAccessInput">
            <Form.Label className="mt-2 mb-0">Access</Form.Label>
            <Form.Control
              as="select"
              name="access"
              onChange={handleInputChange}
              value={getValue('access')}>
              <option value="open">Open</option>
              <option value="registration">Registration</option>
              <option value="ticket">Ticketed</option>
            </Form.Control>
          </Form.Group>
        </Section>

        <Section
          title="Feed Settings"
          eventKey="5"
          className={headerClass}
          icon={faThList}>
          <Form.Group controlId="EventFeedIntroductionInput">
            <Form.Label className="mt-2 mb-0">Introduction</Form.Label>
            <Form.Control
              name="feed_introduction"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="Introduction for the event's feed"
              value={getValue('feed.introduction')}
            />
          </Form.Group>

          <Form.Group controlId="EventImagesInput">
            <Form.Label className="mt-2 mb-0">Banner images</Form.Label>
            <div
              id="EventImagesInput"
              data-target-input="nearest">
              <ImagesSelector
                images={getValue('images')}
                name="images"
                handleChange={setValue}
                container={modalBody}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="EventCommentInput">
            <Form.Label className="mt-2 mb-0">Comments</Form.Label>
            <Form.Check
              type="checkbox"
              name="feed_comments"
              onChange={handleInputChange}
              label="Show comments"
              checked={getValue('feed.comments')}
            />
          </Form.Group>

          <Form.Group controlId="EventFeedContentInput">
            <Form.Label className="mt-2 mb-0">Content</Form.Label>
            <CheckboxGroup
              options={[
                { value: 'events', label: 'Sub-events' },
                { value: 'streamevents', label: 'Streamed content' },
                { value: 'offerings', label: 'Tickets' },
                { value: 'sponsors', label: 'Sponsors' },
                { value: 'schedule', label: 'Schedule' }
              ]}
              name="feed_content"
              value={getValue('feed.content') || ['events', 'streamevents']}
              onChange={setValue}
            />
          </Form.Group>

          <Form.Group controlId="EventFeedGroupingInput">
            <Form.Label className="mt-2 mb-0">Grouping</Form.Label>
            <Form.Control
              as="select"
              name="feed_grouping"
              onChange={handleInputChange}
              value={getValue('feed.grouping')}>
              <option value="">None</option>
              <option value="minute">Minute</option>
              <option value="hour">Hour</option>
              <option value="day">Day</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="EventFeedSortInput">
            <Form.Label className="mt-2 mb-0">Feed order</Form.Label>
            <Form.Control
              as="select"
              name="feed_sort"
              onChange={handleInputChange}
              value={getValue('feed.sort')}>
              <option value="-rating">Rating</option>
              <option value="datetime">Event times</option>
              <option value="-created">Latest post</option>
              <option value="title">Alphabetical</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="EventFeedSplitInput">
            <Form.Check
              type="checkbox"
              name="feed_split"
              onChange={handleInputChange}
              label="Hide past events"
              checked={getValue('feed.split')}
            />
            <Form.Text className="text-muted">
              If ticked, and the feed order is set to 'event times', events that have finished will be hidden by default
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EventFeedCssInput">
            <Form.Label className="mt-2 mb-0">Styles</Form.Label>
            <Form.Control
              name="feed_css"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="Custom CSS for the event's feed"
              value={getValue('feed.css')}
            />
          </Form.Group>

          <Form.Group controlId="EventTagsInput">
            <Form.Label className="mt-2 mb-0">Tags</Form.Label>
            <TagSelector
              tags={tags}
              handleChange={handleTagChange}
            />
          </Form.Group>
        </Section>

        <Section
          title="Foyer Settings"
          eventKey="6"
          className={headerClass}
          icon={faBookReader}>
          <Form.Group controlId="EventFoyerIntroductionInput">
            <Form.Label className="mt-2 mb-0">Introduction</Form.Label>
            <Form.Control
              name="foyer_introduction"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="Introduction for the event's foyer"
              value={getValue('foyer.introduction')}
            />
          </Form.Group>

          <Form.Group controlId="EventFoyerContentInput">
            <Form.Label className="mt-2 mb-0">Content</Form.Label>
            <CheckboxGroup
              options={[
                { value: 'events', label: 'Sub-events' },
                { value: 'streamevents', label: 'Streamed content' },
                { value: 'sponsors', label: 'Sponsors' }
              ]}
              name="foyer_content"
              value={getValue('foyer.content')}
              onChange={setValue}
            />
          </Form.Group>

          <Form.Group controlId="EventFoyerColourInput">
            <Form.Label className="mt-2 mb-0">Highlight colour</Form.Label>
            <Form.Control
              name="foyer_colors_highlight"
              onChange={handleInputChange}
              placeholder="A hex value of the foyer's highlight colour"
              value={getValue('foyer.colors.highlight')}
              pattern="^#([0-9A-F]{3}){1,2}$"
            />
            <Form.Control.Feedback type="invalid">
              This must be a hexadecimal value in the format '#AABBCC'
            </Form.Control.Feedback>
            <Form.Text className="text-muted">Used to add colour to page elements</Form.Text>
          </Form.Group>

          <Form.Group controlId="EventFoyerCoverInput">
            <Form.Label className="mt-2 mb-0">Cover image</Form.Label>
            <div
              className="input-group align-items-start"
              id="EventFoyerCoverInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('foyer.cover')}
                name="foyer_cover"
                handleChange={setValue}
              />
            </div>
            <Form.Text className="text-muted">The image used for the foyer header</Form.Text>
          </Form.Group>

          <Form.Group controlId="EventFoyerTagsInput">
            <Form.Label className="mt-2 mb-0">Tags</Form.Label>
            <TagSelector
              tags={foyerTags}
              handleChange={tags => {
                handleTagChange(tags, 'foyer_tags')
              }}
            />
          </Form.Group>

          <Form.Group controlId="EventFoyerCssInput">
            <Form.Label className="mt-2 mb-0">Styles</Form.Label>
            <Form.Control
              name="foyer_css"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="Custom CSS for the event's foyer"
              value={getValue('foyer.css')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Sponsors"
          eventKey="7"
          className={headerClass}
          icon={faPeopleCarry}>
          <Form.Group controlId="EventSponsorsInput">
            <Form.Label className="mt-2 mb-0">Sponsor logos and links</Form.Label>
            <div
              id="EventSponsorsInput"
              data-target-input="nearest">
              <ImagesSelector
                images={(getValue('sponsors') || []).map(item => ({
                  url: item.logo,
                  caption: item.caption,
                  link: item.link
                }))}
                name="sponsors"
                mode="logo"
                handleChange={(name, data) =>
                  setValue(
                    name,
                    data.map(item => ({ logo: item.url, caption: item.caption, link: item.link }))
                  )
                }
                container={modalBody}
              />
            </div>
          </Form.Group>
        </Section>

        {getTicketPanel('8')}
      </Accordion>

      <Form.Group style={errorStyle}>
        <Form.Control.Feedback type="invalid">{serverError}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={feedbackStyle}>
        <Form.Control.Feedback type="valid-feedback">{feedbackMessage}</Form.Control.Feedback>
      </Form.Group>

      <div className="mt-3">
        <Button
          variant="secondary"
          type="button"
          onClick={handleClose}>
          Close
        </Button>
        {deleteButton}
        <Button
          name="action"
          value="save-close"
          variant="primary"
          type="submit"
          className="ms-2">
          Save &amp; Close
        </Button>
      </div>
      <ConfirmationModal
        title="Delete event"
        body="Deleting an event can't be undone. Are you sure about this?"
        handleDismissal={hideConfirmation}
        handleConfirmation={handleDelete}
        visible={confirmationVisible}
      />
    </Form>
  )
}

export default EditForm
