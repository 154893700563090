import { useDispatch } from 'react-redux'
import { setCartOfferCode } from '../redux/shopping/actions'
import { useEffect } from 'react'

export const useApplyDiscountCode = () => {
  const dispatch = useDispatch()

  const params = new URL(document.location).searchParams
  const entity = params.get('entity')
  const offercode = params.get('offercode')

  return useEffect(() => {
    if (entity && offercode && dispatch) {
      dispatch(setCartOfferCode(entity, offercode))
    }
  }, [entity, offercode, dispatch])
}

export default useApplyDiscountCode
