import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { isPlatformSupport } from '../../redux/user/state'
import { syncEventTickets } from '../../redux/event/actions'
import { isEventAdmin } from '../../redux/state'

export const TicketSyncButton = props => {
  const dispatch = useDispatch()

  const { variant: propVariant = 'primary', id, mode, className, size = 'sm' } = props

  const [label, setLabel] = useState('Sync Ticket Sales')
  const [variant, setVariant] = useState(propVariant)

  const authorised = useSelector(state => isPlatformSupport(state) || isEventAdmin(state, id))

  if (!authorised || !id) return null

  const handleClick = e => {
    setLabel('Synchronising . . .')
    e.stopPropagation()
    dispatch(syncEventTickets(id)).then(status => {
      setLabel(status ? 'Sync Complete' : 'Sync Failed')
      setVariant(status ? 'success' : 'danger')
      setTimeout(() => {
        setLabel('Sync Ticket Sales')
        setVariant(propVariant)
      }, 3000)
    })
  }

  if (mode === 'icon') {
    return (
      <React.Fragment>
        <button
          title={label}
          onClick={handleClick}
          className={className}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </button>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant={variant || 'light'}
        title={label}
        onClick={handleClick}
        className={className}
        size={size}>
        <FontAwesomeIcon
          icon={faSyncAlt}
          className="me-2"
        />
        <span className="text-nowrap">{label}</span>
      </Button>
    </React.Fragment>
  )
}

export default TicketSyncButton
