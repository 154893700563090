import React from 'react'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import TextBlock from '../display/TextBlock'
import TimeRange from '../display/TimeRange'
import TicketButton from './TicketButton'
import EditButton from './EditButton'
import GuestButton from '../guestlist/Button'
import { Link } from 'react-router-dom'
import { getEvent, getEventEntity } from '../../redux/state'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import VenueButton from './VenueButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import UserDownloadButton from './UserDownloadButton'
import OrderDownloadButton from './OrderDownloadButton'
import { useSelector } from 'react-redux'
import ReportButton from './ReportButton'
import ScheduleButton from './schedule/Button'
import BoxOfficeButton from './BoxOfficeButton'
import GuestListButton from './GuestListButton'

export default props => {
  const { id } = props

  const { data, entity, parent } = useSelector(state => {
    const data = getEvent(state, id)

    const entity = getEventEntity(state, id)

    const parent = data && data.parent ? getEvent(state, data.parent) : null

    if (data && data.datetime && data.duration) {
      data.endTime = new Date(new Date(data.datetime).getTime() + data.duration * 60000)
    }

    return {
      data,
      entity,
      parent
    }
  })

  if (!data) return null

  const {
    feed: { content = [] }
  } = data

  const getShopButton = theme => {
    if (entity && entity.product && entity.product.enabled) {
      const variant = theme === 'dark' ? 'light' : 'secondary'
      return (
        <Button
          type="button"
          variant={variant}
          href={`/entities/${entity.alias || entity.id}/products/feed`}
          className="me-2 mb-2">
          Shop
        </Button>
      )
    }
    return null
  }

  const getTimeEmphasis = () => {
    if (!data.datetime) {
      return null
    }
    const threshold = 24 * 3600 * 1000
    const start = new Date(data.datetime)
    const now = new Date()
    if (Math.abs(start.getTime() - now.getTime()) > threshold) {
      return 'date'
    }
    if (data.endTime) {
      const end = new Date(data.endTime)
      if (Math.abs(end.getTime() - start.getTime()) > threshold) {
        return 'date'
      }
    }
    return 'time'
  }

  const getPerformerElement = () => {
    const { performer } = data
    return performer && performer.name ? (
      <p
        key="performer"
        className="feed-card__link feed-card__link--performer m-0 text-white fst-italic">
        {performer.name}
      </p>
    ) : null
  }

  const getReferenceLinks = () => {
    const links = []
    if (entity) {
      //links.push(<React.Fragment key="entity">by <Link to={ '/entities/' + (entity.alias || entity.id) + '/feed'} className="text-white"><strong>{entity.name}</strong></Link></React.Fragment>)
    }
    if (parent) {
      if (entity) {
        //links.push(', ')
      }
      links.push(
        <React.Fragment key="event">
          part of{' '}
          <Link
            to={'/events/' + (parent.alias || parent.id) + '/feed'}
            className="text-white">
            <strong>{parent.name}</strong>
          </Link>
        </React.Fragment>
      )
    }
    return links.length ? (
      <p
        key="reference"
        className="banner__link banner__link-entity m-0 text-white">
        {links}
      </p>
    ) : null
  }

  const start = new Date(data.datetime)
  let buttons = [],
    label,
    timings = [],
    editButtons = []

  if (start) {
    timings.push(
      <span key="time-range">
        <TimeRange
          start={start}
          end={data.endTime}
          emphasis={getTimeEmphasis()}
        />
      </span>
    )
  }

  editButtons.push(
    <GuestButton
      key="guest-button"
      mode="icon"
      resource={{ type: 'events', id: data.id }}
      url={window.location.origin + '/events/' + (data.alias || data.id) + '/feed'}
      className="banner__guest banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />
  )

  // editButtons.push(
  //   <UserDownloadButton
  //     key="users-button"
  //     mode="icon"
  //     resource={{ type: 'events', id: data.id }}
  //     className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
  //   />
  // )

  editButtons.push(
    <GuestListButton
      key="guestlist-button"
      mode="icon"
      id={data.id}
      className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />
  )
  editButtons.push(
    <BoxOfficeButton
      key="boxoffice-button"
      mode="icon"
      id={data.id}
      className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />
  )

  editButtons.push(
    <OrderDownloadButton
      key="orders-button"
      mode="icon"
      id={data.id}
      className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />
  )

  editButtons.push(
    <ReportButton
      key="report-button"
      mode="icon"
      id={data.id}
      className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />
  )

  editButtons.push(
    <EditButton
      key="edit-button"
      mode="icon"
      id={data.id}
      className="banner__edit banner__btn--icon py-2 ps-2 ms-2 text-white ms-auto"
    />
  )

  if (editButtons.length) {
    editButtons = (
      <p className="banner__start d-flex d-md-block justify-content-between ms-md-auto mt-2 mt-md-0 text-white order-1 order-md-2 text-end">
        {editButtons}
      </p>
    )
  }

  if (data.details && data.details.url) {
    label = data.details.text || 'More details'
    buttons.push(
      <Button
        key="more-details"
        type="button"
        variant="light"
        href={data.details.url}
        className="me-2 mb-2"
        title="More details">
        {label}
      </Button>
    )
  }
  if (data.faqs && data.faqs.url) {
    label = data.faqs.text || 'FAQs'
    buttons.push(
      <Button
        key="faqs"
        type="button"
        variant="light"
        href={data.faqs.url}
        className="me-2 mb-2"
        title="Frequently asked questions">
        <FontAwesomeIcon icon={faQuestionCircle} />
        <span className="d-none d-md-inline ms-2">{label}</span>
      </Button>
    )
  }
  buttons.push(getShopButton('dark'))
  buttons.push(
    <TicketButton
      key="tickets"
      resource="events"
      id={id}
      className="me-1 mb-2"
    />
  )

  if (content.includes('schedule')) {
    buttons.push(
      <ScheduleButton
        id={data.alias || data.id}
        className="me-2 mb-2"
      />
    )
  }

  buttons = <span>{buttons}</span>

  const carousel = data.images && data.images.length ? data.images.map(image => image.url) : [data.image]

  const venue = (
    <VenueButton
      id={data.id}
      className="cursor-pointer text-decoration-none text-white fw-bold"
      mode="inline"
    />
  )

  return (
    <div
      className="banner banner--header position-relative"
      style={{ backgroundImage: 'url(' + data.image + ')' }}>
      <Carousel
        fade
        controls={false}
        indicators={false}
        className="position-absolute w-100 h-100">
        {carousel.map((image, index) => (
          <Carousel.Item
            className="w-100 h-100"
            interval="6000"
            style={{ backgroundImage: 'url(' + image + ')' }}></Carousel.Item>
        ))}
      </Carousel>
      <div className="banner__inner position-relative">
        <div className="banner__top">
          <div className="banner__controls sticky-top flex-column flex-md-row d-flex justify-content-between align-items-start px-3 pt-3 pb-4 w-100">
            {buttons}
            {editButtons}
          </div>
        </div>
        <div className="banner__bottom">
          <div className="banner__title px-3 pt-2 w-100">
            {data.logo ? (
              <Row>
                <Col
                  xs={1}
                  sm={2}
                  md={3}
                  lg={4}
                />
                <Col
                  xs={10}
                  sm={8}
                  md={6}
                  lg={4}>
                  <img
                    src={data.logo}
                    alt={data.name}
                    className="w-100"
                  />
                </Col>
                <Col
                  xs={1}
                  sm={2}
                  md={3}
                  lg={4}
                />
              </Row>
            ) : (
              <h1 className="text-white m-0 pt-3">{data.name}</h1>
            )}
            {getPerformerElement()}
            {getReferenceLinks()}
          </div>
          <div
            className={
              data.logo
                ? 'banner__description p-3 w-100 d-flex justify-content-center'
                : 'banner__description p-3 w-100'
            }>
            <div className={data.logo ? 'col-12 col-md-10 col-xl-8 text-center' : 'text-start'}>
              {data.strapline && (data.logo || !data.description) ? (
                <TextBlock
                  key="strapline"
                  text={data.strapline}
                  className={
                    data.logo
                      ? 'h3 mt-0 mb-4 pb-2 text-white banner__strapline text-center'
                      : 'm-0 pb-2 text-white banner__strapline'
                  }
                  linkClass="text-white"
                />
              ) : null}
              {timings ? <p className="m-0 pb-3 text-white">{timings}</p> : null}
              {venue ? <p className="my-3">{venue}</p> : null}
              <TextBlock
                key="description"
                text={data.description}
                className="m-0 pb-2 text-white text-left"
                linkClass="text-white"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
