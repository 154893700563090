import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { getEventOfferingCategories } from '../../redux/event/state'

export const EventOfferingCategoryInput = props => {
  const { event, onChange, value = '', name = 'category', label = 'Category', id, size } = props

  const options = useSelector(state => getEventOfferingCategories(state, event))

  return (
    <Form.Group controlId={id}>
      <Form.Label className="mt-2 mb-0">{label}</Form.Label>
      <Typeahead
        name={name}
        id={id}
        onChange={onChange ? v => onChange(name, v.length ? v[0].label : null) : null}
        options={options.map(option => ({ id: option, label: option }))}
        defaultSelected={value ? [{ id: value, label: value }] : []}
        size={size}
        allowNew={true}
      />
    </Form.Group>
  )
}

export default EventOfferingCategoryInput
