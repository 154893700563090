import { useSelector } from 'react-redux'
import { getEntity, getTenantEntity } from '../../redux/state'

export const TicketSupportLink = props => {
  const { id, className = '', colour = 'black' } = props
  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))

  if (!entity || !entity.email) return null

  return (
    <p className={`text-${colour} ${className}`}>
      If you have any problems with the payment process, please contact us at{' '}
      <a
        href={`mailto:${entity.email}`}
        className={`text-${colour}`}>
        {entity.email}
      </a>
      .
    </p>
  )
}
