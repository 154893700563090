import React from 'react'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { showModal } from '../../redux/ui/actions'
import { getProductPaymentEntity, getProductOfferings, isEntityAdmin } from '../../redux/state'
import { useHistory } from 'react-router-dom'

export const OfferingList = props => {
  const { id } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const data = useSelector(state => getProductOfferings(state, id))

  const entity = useSelector(state => getProductPaymentEntity(state, id))

  const isAdmin = useSelector(state => (entity ? isEntityAdmin(state, entity.id) : false))

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('offerings-products', id, false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  const hideCart = () => {
    dispatch(showModal('offerings-products', id, false))
  }

  if (!data) {
    return null
  }

  return (
    <div>
      {data.map((item, index) => {
        return (
          <Purchase
            key={index}
            offering={item}
            className="mb-2"
            isAdmin={isAdmin}
          />
        )
      })}
      <div className="text-end d-flex d-md-block">
        <Button
          variant="outline-dark"
          className="mt-1 btn-block btn-md-inline me-2 flex-fill"
          onClick={hideCart}>
          Continue
        </Button>
        <CheckoutButton
          className="mt-1 btn-block btn-md-inline flex-fill"
          handleClick={checkout}
        />
      </div>
    </div>
  )
}

export default OfferingList
