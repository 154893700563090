import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { getEventOfferingCategories } from '../../redux/event/state'

export const EventOfferingCategorySelector = props => {
  const { event, onChange, value = '', name = 'category', label = 'Category', id, size } = props

  const options = useSelector(state => getEventOfferingCategories(state, event))

  if (!event || !options) return null

  return (
    <Form.Group controlId={id}>
      <Form.Label className="mt-2 mb-0">{label}</Form.Label>
      <Form.Control
        id={id}
        as="select"
        name={name}
        onChange={onChange}
        value={value}
        size={size}>
        <option value="">All</option>
        {options.map(option => (
          <option
            key={option}
            value={option}>
            {option}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default EventOfferingCategorySelector
